import {
  bankingDetails,
  ContractorProfileInterface,
  ContractorServiceMappingInterface,
  IndustryDataInterface,
  OnBoardingDropdownInterface,
} from "../../../Interface/ManageContractor";

const ServiceProfile = ({
  profileData,
}: {
  profileData: ContractorProfileInterface;
}) => {
  const interestedAssignments: string[] = profileData?.dropdowns
    ?.filter(
      (e: OnBoardingDropdownInterface) =>
        e.dropdown_name.toLowerCase() === "interested assignments"
    )
    ?.map((e: OnBoardingDropdownInterface) => {
      if (e.dropdown_value.toLowerCase()==="other" || e.dropdown_value.toLowerCase()==="others") {
        return e.others_name
      } else {
        return e.dropdown_value
      }
    });

  const reasonForJoining: string[] = profileData?.dropdowns
    ?.filter(
      (e: OnBoardingDropdownInterface) =>
        e.dropdown_name.toLowerCase() === "reason for joining"
    )
    .map((e: OnBoardingDropdownInterface) => {
      if (e.dropdown_value.toLowerCase()==="other" || e.dropdown_value.toLowerCase()==="others") {
        return e.others_name
      } else {
        return e.dropdown_value
      }
    });

  const experiencedIndustries: string[] = profileData?.industry_data?.map(
    (e: IndustryDataInterface) =>
      e.others_name === "" ? e.industry_name : e.others_name
  );

  const bankDetailsBinding = () => {
    return profileData.banking_details.map((bankDetails: bankingDetails, index: number) => {
      return(
      <tr key={index}>
        <td className="ps-4">
          {bankDetails.bank_name}
          {bankDetails.is_default === true && <span> (Primary)</span>}
        </td>
        <td>{bankDetails.account_holder_name}</td>
        <td>{bankDetails.account_number}</td>
        <td>{bankDetails.routing_number}</td>
      </tr>)
    })
  }


  return (
    <div
      // added - "show active"
      className="tab-pane fade show active"
      id="service-profile"
      role="tabpanel"
      aria-labelledby="service-profile-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">
        Industry Experience
      </h3>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Interested Assignments
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Emergency Assignments */}
            {interestedAssignments?.length > 0 ? interestedAssignments.join(", ") : "-"}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Reason for Joining PPS Contractor
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {
             reasonForJoining?.length >0 ? reasonForJoining.join(", ") : "-"
            }
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Experienced Industries
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {
              experiencedIndustries?.length >0 ? experiencedIndustries.join(", ") : "-"
            }
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Services have you offered in the past year?
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {
              profileData?.service_mappings?.filter((ele: ContractorServiceMappingInterface)=>ele.is_past_service===true).map((ele : ContractorServiceMappingInterface)=>{
                if (ele?.request_type_name?.toLowerCase() === "others" ) {
                  return ele?.others_name
                } else {
                  return ele?.request_type_name
                }
              }).join(", ")
            }
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Is there anything about your organization you would like to
            highlight?
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {profileData?.contractor_details?.orgainzation_highlight
              ? profileData?.contractor_details?.orgainzation_highlight
              : "-"}
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Please share any extra training you offer for your guards beyond
            standard licensing
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {profileData?.contractor_details?.extra_guard_trainings
              ? profileData?.contractor_details?.extra_guard_trainings
              : "-"}
          </p>
        </div>

        <div className="d-block">
          <h3 className="font-18 color-black font-semibold mb-4">Account Details</h3>
          <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
            <table className="table table-borderless mb-0 acc-view">
              <thead>
                <tr>
                  <th className="ps-4 text-nowrap">
                    Bank Name #
                  </th>
                  <th className="ps-4 text-nowrap">
                    Account Holder Name
                  </th>
                  <th className=" text-nowrap">Account #</th>
                  <th className=" text-nowrap">Routing #</th>
                </tr>
              </thead>
              <tbody>
                {bankDetailsBinding()}
              </tbody>
            </table>
          </div>
        </div>
        

      </div>
    </div>
  );
};

export default ServiceProfile;
