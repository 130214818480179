import React, { useState, useEffect, useCallback } from 'react';
import { BidInfoRequest } from '../../Interface/BidApproval';
import { getBidInfoApi, reinitiateApi } from '../../Service/BidApprovalServices/BidInfoService';
import { GridData } from '../../Interface/JobsAndBids';
import Loader from '../Loader/Loader';

// Interfaces
interface Bid {
    contractor_id: string;
    contractor_name: string;
    contractor_type: string;
    bid_id: string;
    bid_amount: string;
    total_amount_with_margin: string;
    margin_percentage: string;
    margin_value: string;
    fee_percentage: string;
}

interface ContractorReinitiateProps {
    serviceRequestId: string;
    isClose: () => void;
    isOpen: boolean;
    selectedJob: GridData
}

interface AdminEditedAmountInterface {
    qoutedAmount: string,
    marginAmount: string,
    marginPercentage: string,
    totalAmount: string
}

function ContractorReinitiate({ serviceRequestId, isOpen, isClose, selectedJob }: ContractorReinitiateProps) {
    const [bids, setBids] = useState<Bid[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [bidRequest, setBidRequest] = useState<BidInfoRequest>({
        type: "reinitiate",
        service_request_id: serviceRequestId,
        user_id: "SampleId",
        sort_by: '',
        sort_order: '',
        limit: 50,
    });
    // const [marginPercentage, setMarginPercentage] = useState<string>('');
    // const [marginAmount, setMarginAmount] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [bidDetails, setBidDetails] = useState<AdminEditedAmountInterface>({
        marginPercentage: 0 || "",
        marginAmount: 0 || "",
        qoutedAmount: 0 || "",
        totalAmount: 0 || ""
    })

    const fetchBids = useCallback(async () => {
        try {
            const response = await getBidInfoApi(bidRequest);
            if (response.code === 200) {
                setBids(response.data.bids);
                setBidDetails({
                    marginPercentage: response?.data?.bids[0]?.margin_percentage,
                    marginAmount: String(Number(response?.data?.bids[0]?.margin_value).toFixed(2)),
                    qoutedAmount: response?.data?.bids[0]?.bid_amount.match(/\d+/)[0],
                    totalAmount: String(parseFloat(response?.data?.bids[0]?.total_amount_with_margin.replace(/[^0-9.]/g, ''))) || "0"
                })
            } else {
                console.error('Error fetching bids:', response.message);
            }
        } catch (error) {
            console.error('Error fetching bids:', error);
        }
    }, [bidRequest]);

    useEffect(() => {
        fetchBids();
    }, [fetchBids]);

    // const handleSort = (newSortBy: string) => {
    //     setBidRequest(prev => {
    //         const newSortOrder = prev.sort_by === newSortBy && prev.sort_order === 'desc' ? 'asc' : 'desc';
    //         return {
    //             ...prev,
    //             sort_by: newSortBy,
    //             sort_order: newSortOrder
    //         };
    //     });
    // };
    // useEffect(() => {
    //     calculateTotalWithMargin(bidDetails)
    // }, [bidDetails])


    // const handleMarginPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     console.log(value, "value");

    //     if (value === '' || /^\d*\.?\d*$/.test(value)) {
    //         setBidDetails({ ...bidDetails, marginPercentage: value, marginAmount: "" })
    //         setMarginPercentage(value);
    //         setMarginAmount('');
    //     }
    // };
    // console.log(marginPercentage, "marginPercentage");

    // const handleMarginAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     if (value === '' || /^\d*\.?\d*$/.test(value)) {
    //         setBidDetails({ ...bidDetails, marginAmount: value, marginPercentage: "" })
    //         setMarginAmount(value);
    //         setMarginPercentage('');
    //     }
    // };

    // PS_33 - PS_44 : Calculate total amount with margin
    const calculateTotalWithMargin = (bid: AdminEditedAmountInterface) => {

        setError('')
        let bidValue = parseFloat(String(bid.qoutedAmount).replace(/[^0-9.]/g, '')) ?
            parseFloat(String(bid.qoutedAmount).replace(/[^0-9.]/g, '')) : 0;
        let marginValue = parseFloat(String(bid.marginAmount).replace(/[^0-9.]/g, '')) ? parseFloat(String(bid.marginAmount).replace(/[^0-9.]/g, '')) : 0;
        let marginPercentage = parseFloat(String(bid.marginPercentage)) ? parseFloat(String(bid.marginPercentage)) : 0;
        // const totalAmountWithMarginValue = parseFloat(String(bid.totalAmount).replace(/[^0-9.]/g, ''));

        let resultAmount = bidValue;

        if (marginPercentage && marginPercentage > 0) {
            const percentage = marginPercentage / 100;
            resultAmount = bidValue * (1 + percentage);
            // Calculate marginAmount from marginPercentage
            marginValue = Number((bidValue * percentage).toFixed(2));
        } else if (marginValue && marginValue > 0) {
            const amount = marginValue;
            resultAmount = bidValue + amount;
            // Calculate marginPercentage from marginAmount
            marginPercentage = Number(((amount / bidValue) * 100).toFixed(2));
        }

        setBidDetails({ ...bidDetails, totalAmount: resultAmount.toFixed(2), qoutedAmount: String(bidValue), marginAmount: String(marginValue), marginPercentage: String(marginPercentage) })
        return `${resultAmount.toFixed(2)}`;
    };


    // PS_42 - PS_66 : API call to reinitiate bid
    const handleApprove = async (bid: Bid) => {
        setIsLoading(true);
        const bidValue = parseFloat(bid.bid_amount.replace(/[^0-9.]/g, ''));
        const marginValue = parseFloat(bid.margin_value.replace(/[^0-9.]/g, ''));
        const marginPercentage = parseFloat(bid.margin_percentage);
        // check for 0 in quoted amount and margin amount and margin amount , margin percentage both can not be empty

        if ((bidValue <= 0 || Number.isNaN(bidValue)) || ((marginValue <= 0 || Number.isNaN(marginValue)) && (marginPercentage <= 0 || Number.isNaN(marginPercentage)))) {
            // if both are empty 
            if ((bidValue <= 0 || Number.isNaN(bidValue)) && (marginValue <= 0 || Number.isNaN(marginValue)) && (marginPercentage <= 0 || Number.isNaN(marginPercentage))) {
                setError("Quoted amount and Margin percentage And Margin amount");
            } else {
                setError(bidValue <= 0 || Number.isNaN(bidValue) ? "Quoted amount" : "Margin percentage And Margin amount");
            }
            setIsLoading(false);
            return;
        }

        let edited = false
        // console.log(bids[0]?.bid_amount?.match(/\d+/)?.[0], bidDetails.qoutedAmount, "bids[0].bid_amount, bidDetails.qoutedAmount");

        if (bids[0]?.bid_amount?.match(/\d+/)?.[0] !== bidDetails.qoutedAmount) {
            edited = true
        }
        const approvalRequestData = {
            type: 'rejected',
            bid_person: 'contractor',
            edited: edited ? "bid price" : "",
            service_request_id: bidRequest.service_request_id,
            contractor_id: bid.contractor_id,
            new_offer_price: bidValue, // Original bid price
            margin_percentage: parseFloat(bid.margin_percentage),
            margin_amount: parseFloat(bid.margin_value),
            margin_final_value: parseFloat(bid.total_amount_with_margin) // Final value including margin
        };


        try {
            const response = await reinitiateApi(approvalRequestData);

            if (response.code === 200) {
                setToastMessage('Bid reinitiated successfully');
                setShowToast(true);
                await fetchBids();
                setTimeout(() => setShowToast(false), 3000);
                isClose(); // Close the modal after success
            } else {
                console.error('Error reinitiating bid:', response.message);
                setToastMessage('Error reinitiating bid');
                setShowToast(true);
                setTimeout(() => setShowToast(false), 3000);
            }
        } catch (error) {
            console.error('Error reinitiating bid:', error);
            setToastMessage('Error reinitiating bid');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading &&
                <Loader></Loader>
            }
            <>
                {/* Modal */}
                <div
                    className="modal fade show" id="exampleModal11" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: isOpen ? "block" : "none", backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content border-radius-10">
                            <div className="modal-header border-0 px-4 pt-4 align-items-start">
                                <div className="d-block">
                                    <h5
                                        className="modal-title font-bold font-20 color-black-v2 mb-2"
                                        id="exampleModalLabel"
                                    >
                                        Confirmation on Reinitiate
                                    </h5>
                                    <p className="font-14 font-medium color-grey mb-0">
                                        Job request rejected for{" "}
                                        <span className="font-semibold">{selectedJob?.rejected_reason}</span>
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close custom-btn-close mt-1"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={isClose}
                                />
                            </div>
                            <div className="modal-body px-4 py-2">
                                <div className="row">
                                    <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                                        <p className="font-14 font-semibold primary-text-color mb-2">
                                            Request Raised by
                                        </p>
                                        <p className="font-medium color-black-v1 mb-0 font-14">
                                            {selectedJob?.service_request}
                                        </p>
                                    </div>
                                    <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                                        <p className="font-14 font-semibold primary-text-color mb-2">
                                            Contractor Submitted
                                        </p>
                                        <p className="font-medium color-black-v1 mb-0 font-14">
                                            {selectedJob?.finalcontractor_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="position-relative">
                                            <label
                                                htmlFor="InvoiceAmount"
                                                className="font-semibold font-14 primary-text-color mb-2"
                                            >
                                                Quote Amount
                                            </label>
                                            <div className="d-flex align-items-center invoice-form-field p-0">
                                                <button className="border-0 h-100 amount-btn margin"></button>
                                                <input
                                                    type="text"
                                                    id="InvoiceAmount"
                                                    // className={`form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0 ${error === "Quoted amount" ? 'critical-field' : ''}`}
                                                    className="form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0"
                                                    value={bidDetails.qoutedAmount}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setBidDetails(prev => ({
                                                            ...prev,
                                                            qoutedAmount: value
                                                        }));
                                                        calculateTotalWithMargin({ ...bidDetails, qoutedAmount: value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="position-relative">
                                            <label
                                                htmlFor="InvoiceAmount"
                                                className="font-semibold font-14 primary-text-color mb-2"
                                            >
                                                Margin %
                                            </label>
                                            <div className="d-flex align-items-center invoice-form-field p-0">
                                                <button className="border-0 h-100 amount-btn"></button>
                                                <input
                                                    type="text"
                                                    id="InvoiceAmount"
                                                    // className={`form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0 ${error !== "Quoted amount" ? 'critical-field' : ''}`}
                                                    className="form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0"
                                                    value={bidDetails.marginPercentage}
                                                    onChange={(e) => {
                                                        // handleMarginAmountChange(e)
                                                        const value = e.target.value;
                                                        calculateTotalWithMargin({ ...bidDetails, marginPercentage: value, marginAmount: "0" })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="position-relative">
                                            <label
                                                htmlFor="InvoiceAmount"
                                                className="font-semibold font-14 primary-text-color mb-2"
                                            >
                                                Margin Amount (USD)
                                            </label>
                                            <div className="d-flex align-items-center invoice-form-field p-0">
                                                <button className="border-0 h-100 amount-btn"></button>
                                                <input
                                                    type="text"
                                                    id="InvoiceAmount"
                                                    className="form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0"
                                                    // className={`form-control bg-grey font-semibold color-grey-v3 font-16 invoice-form-field border-0 rounded-0 ${error !== "Quoted amount" ? 'critical-field' : ''}`}
                                                    value={bidDetails.marginAmount}
                                                    onChange={(e) => {
                                                        // handleMarginAmountChange(e)
                                                        const value = e.target.value;
                                                        calculateTotalWithMargin({ ...bidDetails, marginAmount: value, marginPercentage: "0" })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="position-relative">
                                            <label
                                                htmlFor="InvoiceAmount"
                                                className="font-semibold font-14 primary-text-color mb-2"
                                            >
                                                Total Amount
                                            </label>
                                            <div className="d-flex align-items-center p-0">
                                                <span className="font-semibold color-grey-v3 font-16 mt-2 pt-1">
                                                    $<span className="ms-1">{bidDetails?.totalAmount}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {error &&
                                        <p className="font-15 font-regular color-grey-v5 mt-2">
                                            <span className="madatory-text">{error} can not be empty</span>
                                        </p>
                                    }

                                    <p className="font-15 font-regular color-grey-v5 mt-1">
                                        Previous Quoted Amount:<span className="ms-1">{bids[0]?.bid_amount}</span>{" "}
                                    </p>
                                </div>
                                <div className="d-flex gap-1 align-items-start mt-2">
                                    <img
                                        src="../images/info-icon.svg"
                                        alt="info-icon"
                                        className="mt-1"
                                    />
                                    <p className="font-13 color-black mb-0 font-regular">
                                        This bid, with the updated amount, will be sent to the contractor
                                        for approval first. If they reject it, the quoted amount will be
                                        sent to another contractor.
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                                <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn">
                                    Cancel
                                </button>
                                <button
                                    className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal6"
                                    onClick={() => handleApprove({
                                        bid_amount: bidDetails.qoutedAmount,
                                        contractor_id: bids[0]?.contractor_id,
                                        contractor_name: bids[0]?.contractor_name,
                                        contractor_type: bids[0]?.contractor_type,
                                        bid_id: bids[0]?.bid_id,
                                        total_amount_with_margin: bidDetails.totalAmount,
                                        margin_percentage: bidDetails.marginPercentage,
                                        margin_value: bidDetails.marginAmount,
                                        fee_percentage: bids[0]?.fee_percentage
                                    })}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {showToast && (
                <div
                    className="position-fixed top-0 start-50 translate-middle-x p-3"
                    style={{ zIndex: 999 }}
                >
                    <div
                        id="liveToast"
                        className="toast cust-toast w-100 p-1 toast-border show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                            <div className="d-flex align-items-center gap-3">
                                <img src="../images/toast-sucess.svg" alt="Successfully" />
                                <div className="d-block">
                                    <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                                        Bid Approval Status
                                    </p>
                                    <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                        {toastMessage}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowToast(false)}
                                aria-label="Close"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContractorReinitiate;