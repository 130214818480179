import { useEffect, useState } from "react";
import { bankingDetails, ServiceProfileFormProps } from "../../../Interface/EditContractor/Signup";


export function ServiceProfileForm({ formPayload, setFormPayload, handleInputChange,  bankDetails, setBankDetails, bindInterestedAssignmentsDropdown, bindReasonForJoiningDropdown, bindExpriencedIndustryDropdown, bindPastServicesDropdown, showOthers, handlePrevious, handleNext, currentStep, serviceProfileErrors, handleCancel, PastServiceData, IndustriesData, Assignments, ReasonForJoining }: ServiceProfileFormProps) {
    const PastService = PastServiceData.find((ele) => ele.service_name === "Others")
    const Industries = IndustriesData.find((ele) => ele.industry_name === "Other")
    const [NextButtonServiceProfile, setNextButtonServiceProfile] = useState(false)
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

    // PS_CSU__2
    useEffect(() => {
        HandleValid()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPayload, PastServiceData, IndustriesData, Assignments, ReasonForJoining, serviceProfileErrors]);

    useEffect(()=>{
        setFormPayload(prevState => ({
            ...prevState,
            banking_details: bankDetails
        }));    
        
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bankDetails])

    const MAX_OTHERS_LENGTH = 255;

    // PS_CSU__7
    const HandleValid = () => {
        let isFieldValid = false;
        let isNoError = false;
        // /PastServiceData: OfficerService[]

        // Check if all required fields are filled
        if (PastServiceData.length !== 0 && IndustriesData.length !== 0 && ReasonForJoining.length !== 0) {
            isFieldValid = true
        }
        if (serviceProfileErrors.exprienced_industry === "" && serviceProfileErrors.past_services === "" && serviceProfileErrors.interested_assignments === "" && serviceProfileErrors.reason_for_joining === "") {
            isNoError = true
        }
        if (showOthers.industry) {
            let otherIndustry = IndustriesData.find((industry) => industry.industry_name === "Other")
            if (otherIndustry?.others !== "") {
                isFieldValid = true
            } else {
                isFieldValid = false
            }
        }
        if (showOthers.past_service) {
            let otherPastService = PastServiceData.find((pastService) => pastService.service_name === "Others")
            if (otherPastService?.others !== "") {
                isFieldValid = true
            } else {
                isFieldValid = false
            }
        }

        setNextButtonServiceProfile((prev) => (isFieldValid && isNoError))
    }

    // const addNewAccount = (e: any) => {
    //     e.preventDefault();
    //     setNextButtonServiceProfile(false)
    //     setBankDetails(prev => [...prev, {
    //         bank_name: "",
    //         routing_number: "",
    //         account_holder_name: "",
    //         account_number: "",
    //         is_default: false
    //     }]);
    // };

    const validateBankField = (name: string, value: string) => {

        let error = '';
        switch (name) {
            case 'bank_name':
                if (value.trim() === "") {
                    error = "Please Enter the bank name";
                } else {
                    error = "";
                }
                break;
            case 'account_holder_name':
                if (value.trim() === "") {
                    error = "Please Enter the account holder name";
                } else {
                    error = "";
                }
                break;
            case 'account_number':
                if (value.trim() === "") {
                    error = "Please Enter the account number";
                } else {
                    error = "";
                }
                break;
            case 'routing_number':
                if (value.trim() === "") {
                    error = "Please Enter the routing number";
                } else {
                    error = "";
                }
                break;
            default:
                break;
        }
        return error;
    };

    const updateBankDetails = (index: number, field: keyof bankingDetails, value: string) => {
        let error = "";
        error = validateBankField(field, value);

        if (typeof index === 'number') {
            setBankDetails(prevAddresses =>
                prevAddresses.map((bankDetails, i) =>
                    i === index ? { ...bankDetails, [field]: value } : bankDetails
                )
            );
            setErrors(prev => ({
                ...prev,
                [`${field}_${index}`]: error
            }));
            setTouched(prev => ({
                ...prev,
                [`${field}_${index}`]: true
            }));
        }
    }

    // const removeBranch = (index: number) => {
    //     setBankDetails(prev => prev.filter((_, i) => i !== index));
    // };

    return (
        <>
            <div className="d-flex align-items-center gap-3 mb-5">
                <h2 className="color-black font-600 font-18 mb-0">Industry Experience</h2>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="IA" className="font-semibold font-16 color-black-v2 mb-2">
                    Interested Assignments
                </label>
                {/* <select
                    name="example"
                    id="IA"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                </select> */}
                {bindInterestedAssignmentsDropdown()}

                {serviceProfileErrors.interested_assignments !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.interested_assignments}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="lPPS" className="font-semibold font-16 color-black-v2 mb-2">
                    Reason for joining PPS Contractor?{" "}
                    <span className="madatory-text">*</span>
                </label>
                {/* <select
                    name="example"
                    id="lPPS"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                    <option value="option1">Select</option>
                </select> */}
                {bindReasonForJoiningDropdown()}
                {serviceProfileErrors.reason_for_joining !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.reason_for_joining}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="EI" className="font-semibold font-16 color-black-v2 mb-2">
                    Experienced Industries
                    <span className="madatory-text">*</span>
                </label>
                {/* <select
                    name="example"
                    id="EI"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                    <option value="option1">Select</option>
                    
                </select> */}
                {bindExpriencedIndustryDropdown()}
                {serviceProfileErrors.exprienced_industry !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.exprienced_industry}
                    </span>
                }
            </div>
            {showOthers.industry &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify Industry<span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={MAX_OTHERS_LENGTH}
                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${serviceProfileErrors.industry_others !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter other industry"
                        name="industry"
                        value={Industries?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                    {serviceProfileErrors.industry_others !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {serviceProfileErrors.industry_others}
                        </span>
                    }
                </div>}
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="service-2"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Services have you offered in the past year?
                    <span className="madatory-text">*</span>
                </label>
                {bindPastServicesDropdown()}
                {serviceProfileErrors.past_services !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.past_services}
                    </span>
                }

            </div>
            {showOthers.past_service &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify Past Service <span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={MAX_OTHERS_LENGTH}
                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${serviceProfileErrors.past_servcies_others !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter past service"
                        name="past_service"
                        value={PastService?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                    {serviceProfileErrors.past_servcies_others !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {serviceProfileErrors.past_servcies_others}
                        </span>
                    }
                </div>}
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Is there anything about your organization you would like to highlight?
                </label>
                <textarea
                    className="form-control service-form-field w-100 custom-textarea font-14 font-regular"
                    placeholder="Enter your Highlights here"
                    defaultValue={""}
                    name="organization_highlight"
                    maxLength={MAX_OTHERS_LENGTH}
                    value={formPayload.organization_highlight}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Please share any extra training you offer for your guards beyond standard
                    licensing
                </label>
                <textarea
                    className="form-control service-form-field w-100 custom-textarea font-14 font-regular"
                    placeholder="Enter the name of training you offer"
                    defaultValue={""}
                    name="extra_training"
                    maxLength={MAX_OTHERS_LENGTH}
                    value={formPayload.extra_training}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
            </div>


            <>
                {bankDetails?.map((bank, index) => (
                    <>
                        <div key={index} className="row">

                            <div className="d-flex align-items-center justify-content-between mb-5">
                                <h2 className="color-black font-600 font-18 mb-0">Account Details {bank.is_default && <span>(Primary)</span>}</h2>
                                {/* <div className="d-flex gap-2 align-items-center">
                                    <button className="bg-transparent border-0 d-flex  gap-1 align-items-center">
                                        <img src="../images/tick-circle.svg" alt="tick-icon" />
                                        <span className="dark-green-color font-15">Set as Primary</span>
                                    </button>
                                    {index !== 0 &&
                                    <button className="bg-transparent border-0 d-flex  gap-1 align-items-center" onClick={() => removeBranch(index)}>
                                        <img src="../images/remove-icon.svg" alt="remove-icon" />
                                        <span className="color-red font-15">Remove</span>
                                    </button>
                                    }
                                </div> */}
                            </div>

                            <div className="col-lg-6 mb-4 mb-lg-5 d-flex justify-content-between flex-column">
                                <label
                                    htmlFor={`add1-${index}`}
                                    className="font-semibold font-16 color-black-v2 mb-2"
                                >
                                    Bank Name
                                    <span className="madatory-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={`add1-${index}`}
                                    name={`bank_name_${index}`}
                                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                                    placeholder="Enter Bank Name"
                                    disabled={true}
                                    value={bank.bank_name}
                                    onChange={(e) => updateBankDetails(index, 'bank_name', e.target.value)}
                                />
                                {touched[`bank_name_${index}`] && errors[`bank_name_${index}`] &&
                                <span className="font-14 font-medium alert-text">{errors[`bank_name_${index}`]}</span>}
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-5 d-flex justify-content-between flex-column">
                                <label
                                    htmlFor={`add3_${index}`}
                                    className="font-semibold font-16 color-black-v2 mb-2"
                                >
                                    Account Holder Name
                                    <span className="madatory-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={`add3_${index}`}
                                    name={`account_holder_name_${index}`}
                                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                                    placeholder="Enter Account Holder Name"
                                    disabled={true}
                                    value={bank.account_holder_name}
                                    onChange={(e) => updateBankDetails(index, 'account_holder_name', e.target.value)}
                                />
                                {touched[`account_holder_name_${index}`] && errors[`account_holder_name_${index}`] &&
                                    <span className="font-14 font-medium alert-text">{errors[`account_holder_name_${index}`]}</span>}
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-5 d-flex justify-content-between flex-column">
                                <label
                                    htmlFor={`add4_${index}`}
                                    className="font-semibold font-16 color-black-v2 mb-2"
                                >
                                    Account #
                                    <span className="madatory-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={`add4_${index}`}
                                    name={`account_number_${index}`}
                                    disabled={true}
                                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                                    placeholder="Enter Account #"
                                    value={bank.account_number}
                                    onChange={(e) => updateBankDetails(index, 'account_number', e.target.value)}
                                />
                                {touched[`account_number_${index}`] && errors[`account_number_${index}`] &&
                                    <span className="font-14 font-medium alert-text">{errors[`account_number_${index}`]}</span>}
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-5 d-flex justify-content-between flex-column">
                                <label
                                    htmlFor={`add2-${index}`}
                                    className="font-semibold font-16 color-black-v2 mb-2"
                                >
                                    Routing #
                                    <span className="madatory-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={`add2-${index}`}
                                    name={`routing_number_${index}`}
                                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                                    placeholder="Enter Routing #"
                                    disabled={true}
                                    value={bank.routing_number}
                                    onChange={(e) => updateBankDetails(index, 'routing_number', e.target.value)}
                                />
                                {touched[`routing_number_${index}`] && errors[`routing_number_${index}`] &&
                                    <span className="font-14 font-medium alert-text">{errors[`routing_number_${index}`]}</span>}
                            </div>
                        </div>
                    </>
                ))}
                {/* <button className="bg-transparent border-0 d-flex  gap-1 align-items-center px-0"
                    onClick={addNewAccount}
                >
                    <img src="../images/plus-icon.svg" alt="plus-icon" />
                    <span className="link font-15">Add Another Account Details</span>
                </button> */}
            </>


            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={() => { handleCancel() }}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                        onClick={handlePrevious}
                        disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {
                            handleNext()
                        }}
                        disabled={!NextButtonServiceProfile}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
        </>
    )
}