// import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const JobRequestChart = (props: any) => {
  const data = props.props
  // Calculate the min and max values for the YAxis
  const minValue = Math.min(...data.map((item: { value: any; }) => item.value));
  const maxValue = Math.max(...data.map((item: { value: any; }) => item.value));
  return (
    <ResponsiveContainer width="95%" height={180}>
      <AreaChart
        data={data}
        margin={{
          top: 2,
          right: 2,
          left: 2,
          bottom: 2,
        }}
        style={{ fontFamily: "Montserrat" }}
      >
        <defs>
          <linearGradient id="colorWeekly" x1="0" y1="0" x2="1" y2="1">
            <stop offset="5%" stopColor="#8054FB" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8054FB" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={true} vertical={false}
          stroke="#B1B1B1"
          opacity="50%" />
        <XAxis
          dataKey="name"
          axisLine={{ stroke: "none" }}
          tickLine={false}
          // tickFormatter={(tick) => (tick === 0 ? "" : tick)}
          stroke="#B1B1B1"
          opacity="70%"
          fontFamily="Montserrat"
          fontSize={14}
        />
        <YAxis
          dataKey="value"
          tickMargin={10}
          fontSize={12}
          stroke="#B1B1B1"
          fontFamily="Montserrat"
          domain={[minValue, maxValue]} // Set the domain to the min and max values
          ticks={Array.from({ length: 5 }, (_, i) => minValue + (maxValue - minValue) / 4 * i)} // Create 5 ticks
          axisLine={{ stroke: "none" }}
          tickLine={false}
          tickFormatter={(tick: number) =>
            tick === 0 ? "" : `${tick.toLocaleString()}`
          }
        />
        <Area
          type="linear"
          dataKey="value"
          stroke="#8054FB"
          fillOpacity={1}
          fill="url(#colorWeekly)"
          activeDot={{ r: 5 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default JobRequestChart;
