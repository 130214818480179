/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useEffect, useState } from "react";
import { State, City, ICity } from 'country-state-city';
import { ServiceProfileForm } from "./ServiceProfile";
import { PersonnalForm } from "./PersonnalForm";
import { ContactForm } from "./ContactsForm";
import { BranchForm } from "./BranchForm";
import { DocumentsForm } from "./DocumentsForm";
import { OnBoardingForm } from "./OnBoardingForm";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate } from "react-router-dom";
import { DocumentData, DocumentDetailed, Dropdowns, FromCheckBox, FromPayload, industry, industryRes, OfficerService, OnboardingErrors, option, OthersType, PaymentDiscounts, Region, request, resDropdowns, Service, ServiceProfileErrors, ShowOthers, TeamContact, toast, Acknowledgements, DropdownSeleted, Addresses, bankingDetails } from "../../../Interface/EditContractor/Signup";
import { uploadToS3, DownloadS3File, formatPhoneNumberEditContractor, emailRegex, phoneNumberRegex } from "../../../Configs/Constant";
import { EditData } from "../../../Interface/EditContractor/EditSignUp";
import { getRegionData } from "../../../Service/HeaderDetails/HeaderDetail";
import { getDynamicDropdownData, getContractorDetailsById, SaveSignUp, AIVerifyDoc } from "../../../Service/ServiceRequest/ManageContractor/SignUp";
import { CustomToast } from "../../Common/Toast";
import Loader from "../Loader";

// PS_CSU__1
export function ContractorEdit() {

    const [formPayload, setFormPayload] = useState<FromPayload>({
        user_id: "", first_name: "", last_name: "", email_id: "", phone_no: "", address_line_1: "", address_line_2: "", zip_code: "", city: "", state: "", region: "", region_id: "", legal_business_name: "", doing_business_as: "", social_media: "", year_found: "", affiliated: "", extra_training: "", organization_highlight: "", off_duty_police: "", acknowledgements: "", payment_discounts: "", only_contact: "", branches: "", after_hours_contact_name: "", after_hours_contact_phone: "", after_hours_contact_email: "", dropdowns: [], industry: [], officer_services: [], team_contacts: [], documents: [], address: [], banking_details:[]
    });
    const [contacts, setContacts] = useState<TeamContact[]>([]);
    const [selectedServices, setSelectedServices] = useState<OfficerService[]>([]);
    const [serviceProfileErrors, setServiceProfileErrors] = useState<ServiceProfileErrors>({ interested_assignments: "", exprienced_industry: "", reason_for_joining: "", past_services: "", industry_others: "", past_servcies_others: "", security_scope: "", company_size: "" });

    const [dropdownSelected, setDropdownSelected] = useState<DropdownSeleted>({
        job_title: false,
        heard_about_us: false,
        company_size: false,
        interested_assignments: false,
        reason_for_joining: false,
        security_scope: false
    });
    //dorpdown datas.
    const [industryDropdownData, setIndustryDropdownData] = useState<industry[]>([]);
    const [JobTitleData, setJobTitleData] = useState<Dropdowns[]>([]);
    const [HeardAboutUsData, setHeardAboutUsData] = useState<Dropdowns[]>([]);
    const [AssignmentsData, setAssignmentsData] = useState<Dropdowns[]>([]);
    const [PaymentDiscountsData, setPaymentDiscountsData] = useState<PaymentDiscounts[]>([]);
    const [ReasonForJoiningData, setReasonForJoiningData] = useState<Dropdowns[]>([]);
    const [companySizeData, setCompanySizeData] = useState<Dropdowns[]>([]);
    const [securityScopeData, setSecurityScopeData] = useState<Dropdowns[]>([]);
    const [ServciesData, setServciesData] = useState<Service[]>([]);
    const [regionData, setRegionData] = useState<Region[]>([]);
    const [citiesList, setCitiesList] = useState<ICity[]>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastProp, setShowToastProp] = useState<toast>({ title: "", type: "", message: "" });
    // onboarding dropdowns
    const [DropdownData, setDropdownData] = useState<resDropdowns[]>([]);
    const [PastServiceData, setPastServiceData] = useState<OfficerService[]>([]);
    const [IndustriesData, setIndustriesData] = useState<industryRes[]>([]);
    const [addresses, setAddresses] = useState<Addresses[]>([{
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        city: '',
        state: ''
    }]);
    const [bankDetails, setBankDetails] = useState<bankingDetails[]>([{
        bank_name:"", 
        routing_number:"", 
        account_holder_name:"", 
        account_number:"",
        is_default:false
    }]);

    const [termsCondition, setTermsCondition] = useState<FromCheckBox>({
        tracktik_willingness: false,
        solicitation: false,
        indemnification: false
    });

    const [others, setOthers] = useState<OthersType>({
        job_title: "", heard_about_us: "", industry: "", past_service: "", current_service: "",
    });
    const [showOthers, setShowOthers] = useState<ShowOthers>({
        job_title: false,
        heard_about_us: false,
        industry: false,
        past_service: false,
        current_service: false,
    });

    const [OnBoardError, setOnBoardError] = useState<OnboardingErrors>({ first_name: '', last_name: '', job_title: '', email_id: '', phone_no: '', legal_business_name: '', doing_business_as: '', address_line_1: '', address_line_2: '', zip_code: '', city: '', state: '', region: '', social_media: '', job_title_dd: '' });
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
    // service profile - multi select
    const [Assignments, setAssignments] = useState<option[]>([])
    const [ReasonForJoining, setReasonForJoining] = useState<option[]>([])
    const [securityScope, setSecurityScope] = useState<option[]>([])
    const [ExpriencedIndustry, setExpriencedIndustry] = useState<option[]>([])
    const [pastServicesSelect, setPastServicesSelect] = useState<option[]>([])
    const [Acknowledgements, setAcknowledgements] = useState<Acknowledgements>({
        subcontracting: "",
        drugScreen: "",
        randomDrugScreening: "",
        backgroundCheck: ""
    });
    // documents
    const [documentsDetails, setDocumentsDetails] = useState<DocumentDetailed[]>([]);
    const [disablefileUpload, setDisableFileUpload] = useState({
        coi: false,
        w9: false
    })
    const [userid, setUserid] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const stateData = location.state

    // steps
    const steps = ["Onboarding Contact", "Service Profile", "Personnel", "Contacts", "Branches", "Documents"]
    const [currentStep, setCurrentStep] = useState(0);

    // PS_CSU__2
    useEffect(() => {
        fetchDropdownData();
        fetchRegionData();
        if (stateData?.isEdit) {
            setIsEdit(true)
            fetchUserProfileData(stateData?.userID)
            setUserid(stateData?.userID)
        }
        if (userid !== "") {
            fetchUserProfileData(userid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateData?.isEdit, stateData?.userID, userid]);

    useEffect(() => {
        HandleValid()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPayload, termsCondition, OnBoardError, pastServicesSelect, Assignments, ExpriencedIndustry, ReasonForJoining, others, serviceProfileErrors])

    // PS_CSU__3
    // Fetch dynamic dropdown data on component mount
    // Your existing dropdown and region data fetching functions...
    const fetchDropdownData = async () => {
        setShowLoader(true)
        try {
            const response = await getDynamicDropdownData();
            if (response?.code === 200) {

                setIndustryDropdownData(response?.data?.industry);
                setHeardAboutUsData(response?.data?.heard_about_us);
                setAssignmentsData(response?.data?.interested_assignments);
                setReasonForJoiningData(response?.data?.reason_for_joining);
                setPaymentDiscountsData(response?.data?.payment_discounts);
                setJobTitleData(response?.data?.job_titles);
                setServciesData(response?.data?.services);
                setSecurityScopeData(response?.data?.security_scope);
                setCompanySizeData(response?.data?.company_size);
            } else {
                setShowToastProp({ type: "Error", title: "Fatching Data UnSuccessful", message: "Failed to fatch Indusry data" })
                setShowLoader(false)
                setShowToast(true)
            }
        } catch (error: any) {
            console.error(error.message);
            setShowToastProp({ type: "Error", title: "Fatching Data UnSuccessful", message: "Failed to fatch Indusry data" })
            setShowLoader(false)
            setShowToast(true)
        } finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    };
    // PS_CSU__4
    const fetchRegionData = async () => {
        setShowLoader(true)
        try {
            const response = await getRegionData();
            if (response?.code === 200) {

                setRegionData(response?.data);

            } else {
                setShowToastProp({ type: "Error", title: "Fatching Data UnSuccessful", message: "Failed to fatch Region data" })
                setShowLoader(false)
                setShowToast(true)
            }
        } catch (error: any) {
            console.error(error.message);
            setShowToastProp({ type: "Error", title: "Fatching Data UnSuccessful", message: "Failed to fatch Region data" })
            setShowLoader(false)
            setShowToast(true)
        } finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    };

    // Fetch user profile data by ID
    const fetchUserProfileData = async (id: string) => {
        setShowLoader(true)
        try {
            const response = await getContractorDetailsById({ type: "contractor", profile_uuid: id });
            if (response.code === 200) {
                const formPayload = transformResponseToFormPayload(response.data);
                setFormPayload(formPayload);
                // setShowToastProp({ type: "Success", title: "Action Successful", message: "Successfully fetched your profile data" })
                // setShowToast(true)
            } else {
                setShowToastProp({ type: "Error", title: "Action Unsuccessful", message: "Error fetching user profile data" })
                setShowToast(true)

            }
        } catch (error: any) {
            console.error(error.message);
            setShowToastProp({ type: "Error", title: "Action Unsuccessful", message: "Error fetching user profile data" })
            setShowLoader(false)
            setShowToast(true)
        } finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    };

    // this function transform the fetched user details to the form data. 
    const transformResponseToFormPayload = (response: EditData): FromPayload => {

        const contractorDetails = response?.contractor_profile_data?.contractor_details;
        const industries = response?.contractor_profile_data?.industry_data;
        const documents = response?.contractor_profile_data?.documents;
        const serviceMappings = response?.contractor_profile_data?.service_mappings;
        const branchLocations = response?.contractor_profile_data?.branch_locations;
        const teamDetails = response?.contractor_profile_data?.team_details;
        const dropdowns = response?.contractor_profile_data?.dropdowns;
        const bankingDetails = response?.contractor_profile_data?.banking_details;
        setBankDetails(bankingDetails);

        // Transform the documents
        const documentDetails: DocumentDetailed[] = documents.map((doc) => ({
            document_type: doc?.document_type,
            file_name: doc?.file_name, // Extract file name from URL
            state_name: doc?.document_state_name,
            file_url: doc?.document_url,
            license_number: doc?.license_number,
            expire_date: doc?.expiry_date,
            isVerified: calculateDaysLeft(doc?.expiry_date) < 0 ? false : true,
            file: null
        }));
        setDisableFileUpload((prev) => ({ ...prev, coi: documentDetails.some((doc) => doc.document_type === "COI"), w9: documentDetails.some((doc) => doc.document_type === "W9") }));

        setDocumentsDetails(documentDetails)
        // Collect unique industries
        const selectedIndustry: option[] = [];
        const industrySet = new Set<string>();
        const industryResArray: industryRes[] = []
        industries.forEach((industry) => {

            let industryData = {
                industry_uuid: industry?.industry_uuid,
                industry_name: industry?.industry_name,
                others: industry?.others_name
            }
            if (industry?.industry_name === "Other") {
                setShowOthers((prev) => ({ ...prev, industry: true }))
            }
            industryResArray.push(industryData)
            if (!industrySet.has(industry?.industry_uuid)) {
                industrySet.add(industry?.industry_uuid);
                selectedIndustry.push({ label: industry?.industry_name, value: industry?.industry_uuid });
            }
        });

        setExpriencedIndustry(selectedIndustry);
        setIndustriesData(industryResArray)

        // Collect services
        const pastService: OfficerService[] = [];
        const currentServices: OfficerService[] = [];
        // Collect unique industries
        const pastServiceTemp: option[] = [];
        const pastServiceSet = new Set<string>();

        serviceMappings.forEach((service) => {
            const data: OfficerService = {
                service_name: service?.request_type_name,
                service_uuid: service?.request_type_uuid,
                guard_count: service?.guard_count,
                others: service?.others_name
            };
            if (service?.is_past_service === true) {
                pastService.push(data);
                if (service?.others_name !== "") {
                    setShowOthers((prev) => ({ ...prev, past_service: true }));
                }
            } else {
                currentServices.push(data);
                if (service?.others_name !== "") {
                    setShowOthers((prev) => ({ ...prev, current_service: true }));
                }
            }
            if (!pastServiceSet.has(service?.request_type_uuid)) {
                pastServiceSet.add(service?.request_type_uuid);
                pastServiceTemp.push({ label: service?.request_type_name, value: service?.request_type_uuid });
            }
        });

        // Update state with collected unique values
        setPastServiceData(pastService);
        setSelectedServices(currentServices);
        setPastServicesSelect(pastServiceTemp)
        // Transform the addresses
        if (contractorDetails.has_branches === "Yes") {
            const addresses: Addresses[] = branchLocations.map((branch: any) => ({
                address_line_1: branch?.address_line_one,
                address_line_2: branch?.address_line_two,
                zip_code: branch?.zip_code,
                city: branch?.city,
                state: branch?.state
            }));
            if (addresses) {
                setAddresses(addresses)
            }
        }

        // Transform the team details
        const teamContacts: TeamContact[] = teamDetails.map((team: any) => ({
            team_name: team?.team_name,
            name: team?.incharge_name,
            phone_on: team?.incharge_phone,
            email_id: team?.incharge_email
        }));
        if (teamContacts) {
            setContacts(teamContacts)
        }

        // Transform the dropdowns
        const transformedDropdowns: resDropdowns[] = dropdowns.map((dropdown) => ({
            dropdown_uuid: dropdown?.on_boarding_dropdown_uuid,
            dropdown_name: dropdown?.dropdown_name,
            others: dropdown?.others_name
        }));
        if (transformedDropdowns) {
            setDropdownData(transformedDropdowns)
        }
        const tempDropdownData: resDropdowns[] = [];
        const tempAssignments: option[] = [];
        const tempReasonForJoining: option[] = [];
        const tempSecurityScope: option[] = [];
        const assignmentSet = new Set<string>();
        const reasonSet = new Set<string>();
        const securityScopeSet = new Set<string>();

        dropdowns.forEach((dropdown) => {
            if (dropdown?.dropdown_name === "Job Title") {
                tempDropdownData.push({ dropdown_name: "job_title", dropdown_uuid: dropdown?.on_boarding_dropdown_uuid, others: dropdown?.others_name });
                if (dropdown.dropdown_value === "Other") {
                    setShowOthers((prev) => ({ ...prev, job_title: true }));
                }
            }
            if (dropdown?.dropdown_name === "Heard about us") {
                tempDropdownData.push({ dropdown_name: "heard_about_us", dropdown_uuid: dropdown?.on_boarding_dropdown_uuid, others: dropdown?.others_name });
                if (dropdown?.dropdown_value === "Other") {
                    setShowOthers((prev) => ({ ...prev, heard_about_us: true }));
                }
            }
            if (dropdown?.dropdown_name === "Company Size") {
                tempDropdownData.push({ dropdown_name: "Company Size", dropdown_uuid: dropdown?.on_boarding_dropdown_uuid, others: "" });
            }
            if (dropdown.dropdown_name === "Interested Assignments") {
                if (!assignmentSet.has(dropdown?.on_boarding_dropdown_uuid)) {
                    assignmentSet.add(dropdown?.on_boarding_dropdown_uuid);
                    tempAssignments.push({ value: dropdown?.on_boarding_dropdown_uuid, label: dropdown?.dropdown_value });
                }
            }
            if (dropdown?.dropdown_name === "Reason for joining") {
                if (!reasonSet.has(dropdown?.on_boarding_dropdown_uuid)) {
                    reasonSet.add(dropdown?.on_boarding_dropdown_uuid);
                    tempReasonForJoining.push({ value: dropdown?.on_boarding_dropdown_uuid, label: dropdown?.dropdown_value });
                }
            }
            if (dropdown?.dropdown_name === "Security Scope") {
                if (!securityScopeSet.has(dropdown?.on_boarding_dropdown_uuid)) {
                    securityScopeSet.add(dropdown?.on_boarding_dropdown_uuid);
                    tempSecurityScope.push({ value: dropdown?.on_boarding_dropdown_uuid, label: dropdown?.dropdown_value });
                    setDropdownSelected((prev) => ({ ...prev, security_scope: true }));
                }
            }
        });

        // Use state setters once with the final arrays
        if (tempDropdownData) {
            setDropdownData(tempDropdownData);
        }
        if (tempAssignments) {
            setAssignments(tempAssignments);
        }
        if (tempReasonForJoining) {
            setReasonForJoining(tempReasonForJoining);
        }
        if (tempSecurityScope) {
            setSecurityScope(tempSecurityScope);
        }

        setAcknowledgements((prev) => ({ ...prev, drugScreen: "true", subcontracting: "true", randomDrugScreening: "true", backgroundCheck: "true" }))
        const citiesList = City.getCitiesOfState('US', contractorDetails?.business_state);

        setCitiesList(citiesList);
        // Transform the main payload
        const formPayload: FromPayload = {
            doing_business_as: contractorDetails?.dba_business_name || '',
            user_id: contractorDetails?.contractor_uuid,
            first_name: contractorDetails?.first_name,
            last_name: contractorDetails?.last_name,
            email_id: contractorDetails?.contractor_email,
            phone_no: contractorDetails?.phone_number,
            address_line_1: contractorDetails?.business_address_line_one,
            address_line_2: contractorDetails?.business_address_line_two,
            zip_code: contractorDetails?.business_zip_code,
            city: contractorDetails?.business_city,
            state: contractorDetails?.business_state,
            region: contractorDetails?.region,
            region_id: contractorDetails?.region_id,
            legal_business_name: contractorDetails?.legal_business_name,
            social_media: contractorDetails?.website_media_link,
            year_found: contractorDetails?.year_founded || '',
            affiliated: contractorDetails?.affiliated_with || '',
            extra_training: contractorDetails?.extra_guard_trainings || '',
            organization_highlight: contractorDetails?.orgainzation_highlight || '',
            off_duty_police: contractorDetails?.is_abled_off_duty_police,
            acknowledgements: contractorDetails.ack || '',
            payment_discounts: contractorDetails.contractor_payment.contractor_payment_uuid,
            only_contact: contractorDetails.is_only_contact || '',
            branches: contractorDetails.has_branches || '',
            after_hours_contact_name: contractorDetails.after_hours_contact_name,
            after_hours_contact_phone: contractorDetails.after_hours_contact_phone,
            after_hours_contact_email: contractorDetails.after_hours_contact_email,
            dropdowns: transformedDropdowns,
            industry: industryResArray,
            officer_services: selectedServices,
            team_contacts: teamContacts,
            documents: documentDetails,
            address: addresses,
            banking_details: bankDetails
        };
        setTermsCondition({
            tracktik_willingness: true,
            solicitation: true,
            indemnification: true
        })

        return formPayload;

    };


    // PS_CSU__8
    const handleNext = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    // PS_CSU__9
    const handlePrevious = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };
    const presentIn = steps[currentStep];
    const nextStep = currentStep < steps.length - 1 ? steps[currentStep + 1] : 'Completed';



    // PS_CSU__5
    const handleInputChange = (field: string, value: any) => {

        if (field === "state") {
            const citiesList = City.getCitiesOfState('US', value);
            setCitiesList(citiesList);
            setFormPayload({ ...formPayload, [field]: value, city: "" });
        } else if (field === "industry") {
            setOthers((prev) => ({ ...prev, [field]: value }))
            let otherIndustry = IndustriesData?.map((ele) => {
                if (ele.industry_name === "Other") {
                    let data: industryRes = {
                        industry_name: ele.industry_name,
                        industry_uuid: ele.industry_uuid,
                        others: value
                    }
                    return data
                } return ele
            })
            setIndustriesData(otherIndustry)
        } else if (field === "past_service") {
            setOthers((prev) => ({ ...prev, [field]: value }))
            let othersServcies = PastServiceData?.map((ele) => {
                if (ele.service_name === "Others") {
                    let data: OfficerService = {
                        service_name: ele.service_name,
                        service_uuid: ele.service_uuid,
                        others: value,
                        guard_count: "",
                    }
                    return data
                } return ele
            })
            setPastServiceData(othersServcies)
        } else if (field === "job_title") {
            let NewdropdownData = DropdownData?.map((ele) => {
                if (ele.dropdown_name === "job_title") {
                    let data: resDropdowns = {
                        dropdown_uuid: ele.dropdown_uuid,
                        dropdown_name: ele.dropdown_name,
                        others: value
                    }
                    return data
                } else {
                    return ele
                }
            })
            setDropdownData(NewdropdownData)
        } else if (field === "heard_about_us") {
            setOthers((prev) => ({ ...prev, [field]: value }))
            let NewdropdownData = DropdownData?.map((ele) => {
                if (ele.dropdown_name === "heard_about_us") {
                    let data: resDropdowns = {
                        dropdown_uuid: ele.dropdown_uuid,
                        dropdown_name: ele.dropdown_name,
                        others: value
                    }
                    return data
                } else {
                    return ele
                }
            })
            setDropdownData(NewdropdownData)
        } else if (field === "job_title_dd") {
            let jobtitleid = JobTitleData?.find((ele) => ele?.dropdown_uuid === value);

            // Show or hide the "Other" option based on selection
            setShowOthers((prev) => ({ ...prev, job_title: jobtitleid?.dropdown_value === "Other" }));

            // Update DropdownData to only keep the selected job title
            setDropdownData((prev) => {
                // Check if there's already a job title entry
                const jobTitleExists = prev.some(ele => ele.dropdown_name === "job_title");
                if (jobTitleExists) {
                    // If it exists, update it
                    return prev.map(ele =>
                        ele.dropdown_name === "job_title"
                            ? { dropdown_uuid: value, dropdown_name: "job_title", others: "" }
                            : ele
                    );
                } else {
                    // If it doesn't exist, add a new entry
                    return [...prev, { dropdown_uuid: value, dropdown_name: "job_title", others: "" }];
                }
            });
        } else if (field === "heard_about_us_dd") {
            let jobtitleid = HeardAboutUsData?.find((ele) => ele?.dropdown_uuid === value);
            setShowOthers((prev) => ({ ...prev, heard_about_us: jobtitleid?.dropdown_value === "Other" }));

            // Update DropdownData to only keep the selected heard about us option
            setDropdownData((prev) => {
                // Check if there's already a heard about us entry
                const heardAboutUsExists = prev.some(ele => ele.dropdown_name === "heard_about_us");
                if (heardAboutUsExists) {
                    // If it exists, update it
                    return prev.map(ele =>
                        ele.dropdown_name === "heard_about_us"
                            ? { dropdown_uuid: value, dropdown_name: "heard_about_us", others: "" }
                            : ele
                    );
                } else {
                    // If it doesn't exist, add a new entry
                    return [...prev, { dropdown_uuid: value, dropdown_name: "heard_about_us", others: "" }];
                }
            });
        } else if (field === "company_size") {

            // Update DropdownData to only keep the selected heard about us option
            setDropdownData((prev) => {
                // Check if there's already a heard about us entry
                const comapanySizeExists = prev.some(ele => ele.dropdown_name === "Company Size");
                if (comapanySizeExists) {
                    // If it exists, update it
                    return prev.map(ele =>
                        ele.dropdown_name === "Company Size"
                            ? { dropdown_uuid: value, dropdown_name: "Company Size", others: "" }
                            : ele
                    );
                } else {
                    // If it doesn't exist, add a new entry
                    return [...prev, { dropdown_uuid: value, dropdown_name: "Company Size", others: "" }];
                }
            });
        }
        else if (field === "tracktik_willingness") {
            setTermsCondition((prev) => ({ ...prev, tracktik_willingness: !termsCondition.tracktik_willingness }))
        } else if (field === "solicitation") {
            setTermsCondition((prev) => ({ ...prev, solicitation: !termsCondition.solicitation }))
        } else if (field === "indemnification") {
            setTermsCondition((prev) => ({ ...prev, indemnification: !termsCondition.indemnification }))
        } else if (field === "region") {
            let region = regionData?.find((ele) => ele?.region_id === value);
            if (region !== undefined) {
                setFormPayload({ ...formPayload, region_id: region?.region_id })
            }
            setFormPayload({ ...formPayload, region_id: value })
        } else if (field === "phone_no") {
            let phoneNum = formatPhoneNumberEditContractor(value);
            setFormPayload({ ...formPayload, [field]: phoneNum });
        }
        else {
            setFormPayload({ ...formPayload, [field]: value });
        }
        handleValidation(field, value);
    }

    // PS_CSU__6
    const handleValidation = (field: string, value: string) => {
        //check all fields
        if (presentIn === "Onboarding Contact") {
            switch (field) {
                case 'first_name':
                case 'last_name':
                    if (!/^[a-zA-Z]+$/.test(value) || "") {
                        setOnBoardError({ ...OnBoardError, [field]: `Please enter your ${field === 'first_name' ? 'First' : 'Last'} Name` })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'email_id':
                    if (!emailRegex.test(value) || "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter your Email Id" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'phone_no':
                    if (!phoneNumberRegex.test(value) || value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter a valid 10-digit Phone Number" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'zip_code':
                    if (!/^\d{5,10}$/.test(value) || value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter your Zip Code" })

                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'legal_business_as':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, legal_business_name: `Please Enter the Legal Business Name` })
                    } else {
                        setOnBoardError({ ...OnBoardError, legal_business_name: "" })
                    }
                    break;
                case 'doing_business_as':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, doing_business_as: `Please Enter the  DBA` })
                    } else {
                        setOnBoardError({ ...OnBoardError, doing_business_as: "" })
                    }
                    break;
                case 'social_media':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, social_media: `Please provide a valid URL` })
                    } else {
                        setOnBoardError({ ...OnBoardError, social_media: "" })
                    }
                    break;
                case 'address_line_1':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter Address Line 1" })

                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                // case 'address_line_2':
                //     if (value === "") {
                //         setOnBoardError({ ...OnBoardError, [field]: "Please enter Address Line 2" })

                //     } else {
                //         setOnBoardError({ ...OnBoardError, [field]: "" })
                //     }
                //     break;
                case 'state':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter your State", city: "" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "", city: "" })
                    }
                    break;
                case 'city':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please select your City" })

                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'region':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please select your Region" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'job_title_dd':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please select your Job Title" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;
                case 'job_title':
                    if (value === "") {
                        setOnBoardError({ ...OnBoardError, [field]: "Please enter your Job Title" })
                    } else {
                        setOnBoardError({ ...OnBoardError, [field]: "" })
                    }
                    break;

            }

        } else if (presentIn === "Service Profile") {
            switch (field) {
                case 'industry':
                    if (value === "") {
                        setServiceProfileErrors({ ...serviceProfileErrors, industry_others: `Please Enter the details` })
                    } else {
                        setServiceProfileErrors({ ...serviceProfileErrors, industry_others: `` })
                    }
                    break;
                case 'past_service':
                    if (value === "") {
                        setServiceProfileErrors({ ...serviceProfileErrors, past_servcies_others: `Please Enter the details` })
                    } else {
                        setServiceProfileErrors({ ...serviceProfileErrors, past_servcies_others: `` })
                    }
                    break;
            }
        }
        else if (presentIn === "Personnel") {
            switch (field) {
                case 'company_size':
                    if (value === "") {
                        setServiceProfileErrors({ ...serviceProfileErrors, company_size: `Please select the company size` })
                    } else {
                        setServiceProfileErrors({ ...serviceProfileErrors, company_size: `` })
                    }
                    break;
            }
        }
    }

    // const handleInputChanges = (field: string, value: any) => {

    //     // Validation part
    //     const validateField = (field: string, value: string) => {
    //         if (presentIn === "Onboarding Contact") {
    //             switch (field) {
    //                 case 'first_name':
    //                     if (value.length > 60 || !/^[a-zA-Z]+$/.test(value)) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter a valid First Name with up to 60 characters" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'last_name':
    //                     if (value.length > 60 || !/^[a-zA-Z]+$/.test(value)) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter a valid Last Name with up to 60 characters" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'email_id':
    //                     const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //                     if (value.length > 60 || !emailRegex.test(value)) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter a valid Email ID with up to 60 characters" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'phone_no':
    //                     if (value.length !== 10 || !/^\d{10}$/.test(value)) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter a valid 10-digit Phone Number" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'zip_code':
    //                     if (value.length < 5 || value.length > 9 || !/^\d{5,9}$/.test(value)) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter a Zip Code with 5 to 9 digits" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'social_media':
    //                     if (value.length > 255) {
    //                         setOnBoardError({ ...OnBoardError, [field]: `Please provide a valid URL with up to 255 characters` })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'address_line_1':
    //                     if (value.length > 255) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter Address Line 1 with up to 255 characters" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'address_line_2':
    //                     if (value.length > 255) {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter Address Line 2 with up to 255 characters" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'legal_business_as':
    //                     if (value === "") {
    //                         setOnBoardError({ ...OnBoardError, legal_business_name: `Please Enter the Legal Business Name` })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, legal_business_name: "" })
    //                     }
    //                     break;
    //                 case 'doing_business_as':
    //                     if (value === "") {
    //                         setOnBoardError({ ...OnBoardError, doing_business_as: `Please Enter the DBA` })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, doing_business_as: "" })
    //                     }
    //                     break;
    //                 case 'state':
    //                     if (value === "option") {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter your State", city: "" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "", city: "" })
    //                     }
    //                     break;
    //                 case 'city':
    //                     if (value === "option") {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please select your City" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'region':
    //                     if (value === "option") {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please select your Region" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'job_title_dd':
    //                     if (value === "option") {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please select your Job Title" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 case 'job_title':
    //                     if (value === "") {
    //                         setOnBoardError({ ...OnBoardError, [field]: "Please enter your Job Title" })
    //                     } else {
    //                         setOnBoardError({ ...OnBoardError, [field]: "" })
    //                     }
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         } else if (presentIn === "Service Profile") {
    //             switch (field) {
    //                 case 'industry':
    //                     if (value === "") {
    //                         setServiceProfileErrors({ ...serviceProfileErrors, industry_others: `Please Enter the details` })
    //                     } else {
    //                         setServiceProfileErrors({ ...serviceProfileErrors, industry_others: `` })
    //                     }
    //                     break;
    //                 case 'past_service':
    //                     if (value === "") {
    //                         setServiceProfileErrors({ ...serviceProfileErrors, past_servcies_others: `Please Enter the details` })
    //                     } else {
    //                         setServiceProfileErrors({ ...serviceProfileErrors, past_servcies_others: `` })
    //                     }
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     };

    //     // State update part
    //     if (field === "state") {
    //         const citiesList = City.getCitiesOfState('US', value);
    //         setCitiesList(citiesList);
    //         setFormPayload({ ...formPayload, [field]: value });
    //     } else if (field === "industry") {
    //         setOthers((prev) => ({ ...prev, [field]: value }))
    //         let otherIndustry = IndustriesData?.map((ele) => {
    //             if (ele.industry_name === "Other") {
    //                 let data: industryRes = {
    //                     industry_name: ele.industry_name,
    //                     industry_uuid: ele.industry_uuid,
    //                     others: value
    //                 }
    //                 return data
    //             } return ele
    //         })
    //         setIndustriesData(otherIndustry)
    //     } else if (field === "past_service") {
    //         setOthers((prev) => ({ ...prev, [field]: value }))
    //         let othersServcies = PastServiceData?.map((ele) => {
    //             if (ele.service_name === "Others") {
    //                 let data: OfficerService = {
    //                     service_name: ele.service_name,
    //                     service_uuid: ele.service_uuid,
    //                     others: value,
    //                     guard_count: "",
    //                 }
    //                 return data
    //             } return ele
    //         })
    //         setPastServiceData(othersServcies)
    //     } else if (field === "job_title") {
    //         let NewdropdownData = DropdownData?.map((ele) => {
    //             if (ele.dropdown_name === "job_title") {
    //                 let data: resDropdowns = {
    //                     dropdown_uuid: ele.dropdown_uuid,
    //                     dropdown_name: ele.dropdown_name,
    //                     others: value
    //                 }
    //                 return data
    //             } else {
    //                 return ele
    //             }
    //         })
    //         setDropdownData(NewdropdownData)
    //     } else if (field === "heard_about_us") {
    //         setOthers((prev) => ({ ...prev, [field]: value }))
    //         let NewdropdownData = DropdownData?.map((ele) => {
    //             if (ele.dropdown_name === "heard_about_us") {
    //                 let data: resDropdowns = {
    //                     dropdown_uuid: ele.dropdown_uuid,
    //                     dropdown_name: ele.dropdown_name,
    //                     others: value
    //                 }
    //                 return data
    //             } else {
    //                 return ele
    //             }
    //         })
    //         setDropdownData(NewdropdownData)
    //     } else if (field === "job_title_dd") {
    //         let jobtitleid = JobTitleData?.find((ele) => ele?.dropdown_uuid === value);

    //         // Show or hide the "Other" option based on selection
    //         setShowOthers((prev) => ({ ...prev, job_title: jobtitleid?.dropdown_value === "Other" }));

    //         // Update DropdownData to only keep the selected job title
    //         setDropdownData((prev) => {
    //             // Check if there's already a job title entry
    //             const jobTitleExists = prev.some(ele => ele.dropdown_name === "job_title");
    //             if (jobTitleExists) {
    //                 // If it exists, update it
    //                 return prev.map(ele =>
    //                     ele.dropdown_name === "job_title"
    //                         ? { dropdown_uuid: value, dropdown_name: "job_title", others: "" }
    //                         : ele
    //                 );
    //             } else {
    //                 // If it doesn't exist, add a new entry
    //                 return [...prev, { dropdown_uuid: value, dropdown_name: "job_title", others: "" }];
    //             }
    //         });
    //     } else if (field === "heard_about_us_dd") {
    //         let jobtitleid = HeardAboutUsData?.find((ele) => ele?.dropdown_uuid === value);
    //         setShowOthers((prev) => ({ ...prev, heard_about_us: jobtitleid?.dropdown_value === "Other" }));

    //         // Update DropdownData to only keep the selected heard about us option
    //         setDropdownData((prev) => {
    //             // Check if there's already a heard about us entry
    //             const heardAboutUsExists = prev.some(ele => ele.dropdown_name === "heard_about_us");
    //             if (heardAboutUsExists) {
    //                 // If it exists, update it
    //                 return prev.map(ele =>
    //                     ele.dropdown_name === "heard_about_us"
    //                         ? { dropdown_uuid: value, dropdown_name: "heard_about_us", others: "" }
    //                         : ele
    //                 );
    //             } else {
    //                 // If it doesn't exist, add a new entry
    //                 return [...prev, { dropdown_uuid: value, dropdown_name: "heard_about_us", others: "" }];
    //             }
    //         });
    //     } else if (field === "tracktik_willingness") {
    //         setTermsCondition((prev) => ({ ...prev, tracktik_willingness: !termsCondition.tracktik_willingness }))
    //     } else if (field === "solicitation") {
    //         setTermsCondition((prev) => ({ ...prev, solicitation: !termsCondition.solicitation }))
    //     } else if (field === "indemnification") {
    //         setTermsCondition((prev) => ({ ...prev, indemnification: !termsCondition.indemnification }))
    //     } else if (field === "region") {
    //         let region = regionData?.find((ele) => ele?.region_id === value);
    //         if (region !== undefined) {
    //             setFormPayload({ ...formPayload, region_id: region?.region_id })
    //         }
    //         setFormPayload({ ...formPayload, region_id: value })
    //     } else {
    //         setFormPayload({ ...formPayload, [field]: value });
    //     }

    //     // Call validation after updating state
    //     validateField(field, value);
    // }

    // PS_CSU__7

    const HandleValid = () => {
        let isFieldValid = false;
        let isNoError = false;
        // Check if all required fields are filled
        if (presentIn === "Onboarding Contact") {
            if (formPayload.first_name !== "" && formPayload.last_name !== "" && formPayload.address_line_1 !== "" && formPayload.zip_code !== "" && formPayload.social_media !== "" && formPayload.state !== "" && formPayload.region_id !== "" && formPayload.doing_business_as !== "" && formPayload.legal_business_name !== "" && termsCondition.tracktik_willingness !== false && termsCondition.indemnification !== false && termsCondition.solicitation !== false) {
                isFieldValid = true

                if (citiesList.length && (formPayload.city === "")) {
                    isFieldValid = false
                }
                let job = DropdownData.find((ele) => ele.dropdown_name === "job_title")
                if (job === undefined) {
                    isFieldValid = false
                } else {
                    let otherJob = JobTitleData.find((ele) => ele.dropdown_value === "Other")
                    if (otherJob !== undefined && (job.dropdown_uuid === otherJob.dropdown_uuid) && job.others === "") {
                        isFieldValid = false
                    }
                }
            }

            if (showOthers.job_title === true) {
                if (OnBoardError.job_title !== "") {
                    isNoError = false
                }
            }

            if (OnBoardError.first_name !== "" || OnBoardError.last_name !== "" || OnBoardError.address_line_1 !== "" || OnBoardError.zip_code !== "" || OnBoardError.social_media !== "" || OnBoardError.state !== "" || OnBoardError.city !== "" || OnBoardError.doing_business_as !== "" || OnBoardError.legal_business_name !== "" || OnBoardError.job_title !== "" || OnBoardError.phone_no !== "" || OnBoardError.email_id !== "" || OnBoardError.region !== "") {
                isNoError = false
            } else {
                isNoError = true
            }


            setNextButtonEnabled((prev) => (isFieldValid && isNoError))
        }
    }

    // PS_CSU__10 
    /*onboarding and servcie profile form cancel function */
    const handleCancel = () => {
        if (presentIn === "Onboarding Contact") {
            setFormPayload((prev) => ({ ...prev, first_name: "", last_name: "", legal_business_name: "", doing_business_as: "", region: "", region_id: "", email_id: "", phone_no: "", address_line_1: "", address_line_2: "", zip_code: "", state: "", city: "", social_media: "", year_found: "", affiliated: "" }))
            setTermsCondition((prev) => ({ tracktik_willingness: false, solicitation: false, indemnification: false }))

        } else if (presentIn === "Service Profile") {
            setFormPayload((prev) => ({ ...prev, organization_highlight: "", extra_training: "" }))
            setAssignments([])
            setExpriencedIndustry([])
            setPastServicesSelect([])
            setReasonForJoining([])
        }
        navigate(-1)
    }
    // PS_CSU__11
    const bindRegionDropdown = () => {
        return regionData?.map((data) => {
            return (<><option value={data.region_id}>{data.region_name}</option></>)
        })
    }
    // PS_CSU__12
    const bindJobtitleDropdown = () => {
        return JobTitleData?.map((data) => {
            return (<><option value={data.dropdown_uuid}>{data.dropdown_value}</option></>)
        })
    }
    // PS_CSU__13
    const bindHeardAboutusDropdown = () => {
        return HeardAboutUsData?.map((data) => {
            return (<><option value={data.dropdown_uuid}>{data.dropdown_value}</option></>)
        })
    }

    const Assignmentsoptions = AssignmentsData?.map((data) => ({
        label: data.dropdown_value,
        value: data.dropdown_uuid
    }));

    //dont do any things you plan to add the data in final payload. 
    // PS_CSU__14
    const bindInterestedAssignmentsDropdown = () => {
        const handleAssignments = (e: any) => {
            if (e?.length === 0) {
                setServiceProfileErrors((prev) => ({ ...prev, interested_assignments: "Please enter your Interested Assignments" }))
            } else {
                setServiceProfileErrors((prev) => ({ ...prev, interested_assignments: "" }))

            }
            setAssignments(e)
        }
        return (<MultiSelect
            options={Assignmentsoptions}
            value={Assignments}
            onChange={handleAssignments}
            labelledBy="Select"
            className=" d-block font-14 font-regular"
        // className={` form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular `}
        />)
    }

    const ReasonForJoiningoptions = ReasonForJoiningData?.map((data) => ({
        label: data.dropdown_value,
        value: data.dropdown_uuid
    }));

    // PS_CSU__15
    const bindReasonForJoiningDropdown = () => {
        const handleReasonForJoining = (e: any) => {
            setReasonForJoining(e)
            if (e?.length === 0) {
                setServiceProfileErrors((prev) => ({ ...prev, reason_for_joining: "Please select the reason for joining PPS Contractor" }))
            } else {
                setServiceProfileErrors((prev) => ({ ...prev, reason_for_joining: "" }))
            }
        }
        return (<MultiSelect
            options={ReasonForJoiningoptions}
            value={ReasonForJoining}
            onChange={handleReasonForJoining}
            labelledBy="Select"
            className=" d-block font-14 font-regular"
        />)
    }

    const ExpriencedIndustryOption = industryDropdownData?.map((data) => ({
        label: data.industry_name,
        value: data.industry_uuid
    }));

    // PS_CSU__16
    const bindExpriencedIndustryDropdown = () => {
        const handleExpriencedIndustry = (e: any) => {
            setExpriencedIndustry(e)


            if (e?.length === 0) {
                setOthers((prev) => ({ ...prev, industry: "" }))
                setShowOthers((prev) => ({ ...prev, industry: false }))
                setServiceProfileErrors((prev) => ({ ...prev, exprienced_industry: "Please select your Experienced Industries." }))
            } else {
                setServiceProfileErrors((prev) => ({ ...prev, exprienced_industry: "" }))
                let otherSelected = e?.filter((ele: any) => ele.label === "Other")
                let data = e?.map((ele: any) => {
                    let data2: industryRes = {
                        industry_uuid: ele?.value,
                        industry_name: ele?.label,
                        others: ""
                    }
                    return data2
                })
                setIndustriesData(data)

                if (otherSelected?.length) {
                    setShowOthers((prev) => ({ ...prev, industry: true }))
                } else {
                    setShowOthers((prev) => ({ ...prev, industry: false }))
                }

            }

        }
        return (<MultiSelect
            options={ExpriencedIndustryOption}
            value={ExpriencedIndustry}
            onChange={handleExpriencedIndustry}
            labelledBy="Select"
            className=" d-block font-14 font-regular"
        />)
    }

    const options = ServciesData?.map((data) => ({
        label: data.service_name,
        value: data.service_uuid
    }));

    // PS_CSU__17
    const bindPastServicesDropdown = () => {
        const handleService = (e: any) => {
            setPastServicesSelect(e)
            if (e?.length === 0) {
                setOthers((prev) => ({ ...prev, past_service: "" }))
                setShowOthers((prev) => ({ ...prev, past_service: false }))
                setServiceProfileErrors((prev) => ({ ...prev, past_services: "Please select the services you have offered in the past year." }))
            } else {

                setServiceProfileErrors((prev) => ({ ...prev, past_services: "" }))
                let data = e?.map((ele: any) => {
                    let data2: OfficerService = {
                        service_uuid: ele?.value,
                        service_name: ele?.label,
                        others: "",
                        guard_count: ""
                    }
                    return data2
                })
                setPastServiceData(data)
                let otherSelected = e?.filter((ele: any) => ele.label === "Others")
                if (otherSelected?.length) {
                    setShowOthers((prev) => ({ ...prev, past_service: true }))
                } else {
                    setOthers((prev) => ({ ...prev, past_service: "" }))
                    setShowOthers((prev) => ({ ...prev, past_service: false }))
                }
            }
        }
        return (<MultiSelect
            options={options}
            value={pastServicesSelect}
            onChange={handleService}
            labelledBy="Select"
            className=" d-block font-14 font-regular"
        />)
    }

    // PS_CSU__18
    const bindStateDropdown = () => {
        return State.getStatesOfCountry('US').map((state) => (
            <option key={state.isoCode} value={state.isoCode}>
                {state.name}
            </option>
        ))
    }

    // PS_CSU__19
    const bindCityDropdown = () => {
        return citiesList.map((city: ICity) => {
            return (
                <option key={city.name} value={city.name}>
                    {city.name}
                </option>
            )
        })
    }

    const SecurityScopeoptions = securityScopeData?.map((data) => ({
        label: data.dropdown_value,
        value: data.dropdown_uuid
    }));

    // PS_CSU__15
    const bindSecurityScopeDropdown = () => {
        const handleSecurityScope = (e: any) => {
            setSecurityScope(e)
            if (e?.length === 0) {
                setServiceProfileErrors((prev) => ({ ...prev, security_scope: "Please select the Security Scope" }))
                setDropdownSelected((prev) => ({ ...prev, security_scope: false }))
            } else {
                setServiceProfileErrors((prev) => ({ ...prev, security_scope: "" }))
                setDropdownSelected((prev) => ({ ...prev, security_scope: true }))
            }
        }
        return (<MultiSelect
            options={SecurityScopeoptions}
            value={securityScope}
            onChange={handleSecurityScope}
            labelledBy="Select"
            className=" d-block font-14 font-regular"
        />)
    }

    const bindCompanySizeDropdown = () => {
        return companySizeData?.map((data) => {
            return (<><option value={data.dropdown_uuid}>{data.dropdown_value}</option></>)
        })
    }

    // PS_CSU__22
    const SaveFromDetails = async () => {

        setShowLoader(true)
        let contractor_data: FromPayload
        // others
        for (let i = 0; i < Assignments.length; i++) {

            DropdownData.push({ dropdown_name: "interested_assignments", dropdown_uuid: Assignments[i].value, others: "" })
        }
        for (let i = 0; i < ReasonForJoining.length; i++) {
            DropdownData.push({ dropdown_name: "Reason For Joining", dropdown_uuid: ReasonForJoining[i].value, others: "" })
        }
        for (let i = 0; i < securityScope.length; i++) {
            DropdownData.push({ dropdown_name: "Security Scope", dropdown_uuid: securityScope[i].value, others: "" })
        }

        // const documents: DocumentDetailed[] = [
        //     {
        //         document_type: "State licenses",
        //         file_name: "Florida license",
        //         state_name: "Florida",
        //         file_url: "https://example.com/documents/state_license.pdf",
        //         license_number: "",
        //         expire_date: "2025-12-01",
        //         file: null,  // Assuming the actual file is not being attached here
        //         isVerified: true
        //     },
        //     {
        //         document_type: "COI",
        //         file_name: "certificate_of_insurance.pdf",
        //         state_name: "",
        //         file_url: "https://example.com/documents/certificate_of_insurance.pdf",
        //         license_number: "",
        //         expire_date: "",
        //         file: null,  // Assuming the actual file is not being attached here
        //         isVerified: false
        //     },
        //     {
        //         document_type: "W9",
        //         file_name: "w9_form.pdf",
        //         state_name: "",
        //         file_url: "https://example.com/documents/w9_form.pdf",
        //         license_number: "",
        //         expire_date: "",
        //         file: null,  // Assuming the actual file is not being attached here
        //         isVerified: true
        //     }
        // ];

        let Services: OfficerService[] = [...PastServiceData, ...selectedServices]
        let region_name = ""
        let region = regionData?.find((ele) => ele?.region_id === formPayload.region_id);
        if (region !== undefined) {
            region_name = region?.region_name
        }
        contractor_data = {
            user_id: userid,
            doing_business_as: formPayload.doing_business_as,
            email_id: formPayload.email_id,
            phone_no: formPayload.phone_no,
            address_line_1: formPayload.address_line_1,
            address_line_2: formPayload.address_line_2,
            zip_code: formPayload.zip_code,
            city: formPayload.city,
            state: formPayload.state,
            region: region_name,
            region_id: formPayload.region_id,
            first_name: formPayload.first_name,
            last_name: formPayload.last_name,
            legal_business_name: formPayload.legal_business_name,
            social_media: formPayload.social_media,
            year_found: formPayload.year_found,
            affiliated: formPayload.affiliated,
            organization_highlight: formPayload.organization_highlight,
            extra_training: formPayload.extra_training,
            off_duty_police: formPayload.off_duty_police,
            acknowledgements: formPayload.acknowledgements,
            payment_discounts: formPayload.payment_discounts,
            only_contact: formPayload.only_contact,
            after_hours_contact_name: formPayload.after_hours_contact_name,
            after_hours_contact_phone: formPayload.after_hours_contact_phone,
            after_hours_contact_email: formPayload.after_hours_contact_email,
            branches: formPayload.branches,
            address: formPayload.branches === "No" ? [] : addresses,
            industry: IndustriesData,
            dropdowns: DropdownData,
            officer_services: Services,
            team_contacts: formPayload.only_contact === "Yes" ? [] : contacts,
            // documents: documents
            documents: documentsDetails,
            banking_details: formPayload.banking_details
        }

        let payload = {
            type: "Contractor",
            mode: isEdit ? "Edit" : "Add",
            data: { contractor_data }
        }

        try {
            const response = await SaveSignUp(payload);

            if (response?.code === 200) {
                if (response?.message === "409") {
                    setShowToastProp({ type: "Error", title: "Creation Failed", message: "User Already Exist" })
                    setShowToast(true)
                } else if (!isEdit) {
                    navigate("/under-review")
                } else {
                    navigate(-1)
                    setShowToastProp({ type: "Success", title: "Updated Sccessfully", message: "User Details Updated sccessfully" })
                }
                setShowToast(true)
            } else {
                setShowToastProp({ type: "Error", title: "Created User Failed", message: "Internal Server Error" })
                setShowToast(true)
            }
        } catch (error) {
            console.error('Failed to saving form data', error);
            setShowToastProp({ type: "Error", title: "Created User Failed", message: "User Created Failed" })
            setShowToast(true)
        }
        finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    }

    //documents 
    const [buttonVerify, setButtonVerify] = useState(false);
    const [buttonSubmit, setButtonSubmit] = useState(false);

    const [error, setError] = useState({
        COI: "",
        W9: "",
        licenses: ""
    });


    useEffect(() => {
        // if (isEdit) {
        //     setDisableFileUpload({ coi: true, w9: true })
        // }
        // const docArr = ["State licenses", "COI", "W9"];
        let obj: any = {
            "State licenses": false,
            "COI": false,
            "W9": false
        };
        let checkFilesUploaded = false;
        documentsDetails.forEach(
            (doc) => {
                if (doc.document_type === "State licenses") {
                    obj["State licenses"] = true;
                }
                else if (doc.document_type === "COI") {
                    obj["COI"] = true;
                }
                else if (doc.document_type === "W9") {
                    obj["W9"] = true;
                }
                if (Object.values(obj).every((val: any) => val === true)) {
                    checkFilesUploaded = true;
                }
            }
        )

        // && documentsDetails.some((doc) => doc.document_type === "COI")
        //     && documentsDetails.some((doc) => doc.document_type === "W9");
        const checkFilesVerified = documentsDetails.every(
            (doc) => doc.isVerified === true && (doc.document_type === "State licenses" || doc.document_type === "COI" || doc.document_type === "W9")
        )
        const isErr = error.COI !== "" || error.W9 !== "" || error.licenses !== "";
        console.log(isErr, "isErr");

        if (checkFilesUploaded) {
            setButtonVerify(true && !isErr);
            setButtonSubmit(false);

        } else {
            setButtonVerify(false && !isErr);
        }
        if (checkFilesVerified && checkFilesUploaded) {
            setButtonSubmit(true);
        }
        else {
            setButtonSubmit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsDetails]);


    // PS_CSU__20
    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>, type: 'State licenses' | 'W9' | 'COI') => {
        const file = event.target.files?.[0];
        if (file) {

            // Check if the file with the same name and type already exists
            const fileExists = documentsDetails.some(
                doc => doc.document_type === type && doc.file_name === file.name
            );

            if (fileExists) {
                if (type === 'State licenses') {
                    setError((prev) => ({ ...prev, licenses: `${type} file with the same name ${file.name} is already uploaded.` }));
                }
                setError((prev) => ({ ...prev, [type]: `${type} file with the same name ${file.name} is already uploaded.` }));
                return;
            }

            if (file.size > 5 * 1024 * 1024) { // 5MB in bytes
                if (type === 'State licenses') {
                    setError((prev) => ({ ...prev, licenses: `State License document should not exceed 5 MB` }));
                } else if (type === 'COI') {
                    setError((prev) => ({ ...prev, COI: `Certificate of Insurance document should not exceed 5 MB.` }));
                }
                setError((prev) => ({ ...prev, [type]: `W-9 form should not exceed 5 MB.` }));
                return;
            }
            if (type === "COI") {
                setDisableFileUpload((prev) => ({ ...prev, coi: true }))
            }
            if (type === "W9") {
                setDisableFileUpload((prev) => ({ ...prev, w9: true }))
            }
            const reader = new FileReader();
            reader.onload = () => {
                const newDoc: DocumentDetailed = {
                    document_type: type,
                    file_name: file.name,
                    state_name: '',
                    file_url: '',
                    license_number: '',
                    expire_date: '',
                    file: file,
                    isVerified: false
                };
                setButtonVerify(true)
                setButtonSubmit(false)
                setDocumentsDetails(prevDocs => [...prevDocs, newDoc]);
            };
            reader.readAsDataURL(file);
            if (type === 'State licenses') {
                setError((prev) => ({ ...prev, licenses: "" }));
            }
            setError((prev) => ({ ...prev, [type]: "" }));

        }
    };

    // PS_CSU__21
    const removeFile = (index: number, type: string, fileName: string) => {
        debugger;
        console.log(type, fileName, "type, fileName");


        if (type === 'State licenses') {
            setError((prev) => ({ ...prev, licenses: "" }));
        }
        setError((prev) => ({ ...prev, [type]: "" }));
        setDocumentsDetails(prevDocs => {
            return prevDocs.filter((doc, i) => {

                // Keep the document if it does not match both the index and type
                return !(doc.document_type === type && doc.file_name === fileName);
            });
        });
        if (type === "COI") {
            setDisableFileUpload((prev) => ({ ...prev, coi: false }))
        }
        if (type === "W9") {
            setDisableFileUpload((prev) => ({ ...prev, w9: false }))
        }
    };


    // PS_CSU__23
    const verifyAndProcessDocuments = async (requests: request[]) => {

        if (requests.length === 0) return true;

        try {
            const response = await AIVerifyDoc(requests);
            if (response) {
                const AIResponse: DocumentData = response;

                setDocumentsDetails((documentsDetails) => (documentsDetails.map((doc) => {
                    if (requests.some((req) => req.documentType === 'License')) {
                        if (doc.document_type === 'State licenses') {
                            const extractedItem = AIResponse.license.find(
                                (item) => item.olddocumentName === doc.file_name
                            );

                            if (extractedItem?.data?.expiry_date !== "Not found" && extractedItem?.NewDocumentName !== "Not found License") {
                                if (extractedItem && !doc.isVerified) {
                                    const isExpired = new Date(extractedItem.data.expiry_date) < new Date();
                                    if (isExpired) {
                                        setError((prev) => ({ ...prev, licenses: `The uploaded ${extractedItem.NewDocumentName} has expired.` }));
                                        return {
                                            ...doc,
                                            isVerified: false
                                        };
                                    }
                                    return {
                                        ...doc,
                                        file_name: extractedItem.NewDocumentName,
                                        state_name: extractedItem.data.state,
                                        expire_date: extractedItem.data.expiry_date,
                                        isVerified: true,
                                    };
                                }
                            } else if (extractedItem?.data?.expiry_date === "Not found" || extractedItem?.NewDocumentName === "Not found License") {
                                // setError((prev) => ({ ...prev, licenses: `Invalid State licenses Document ${extractedItem.olddocumentName}` }))
                                setError((prev) => ({ ...prev, licenses: "Invalid State license Document" }))
                                return { ...doc, isVerified: false };
                            } else {
                                setError((prev) => ({ ...prev, licenses: "Invalid State license Document" }))
                                return { ...doc, isVerified: false };
                            }
                        }
                    }
                    if (requests.some((req) => req.documentType === 'COI')) {
                        if (doc.document_type === 'COI') {
                            const extractedItem = AIResponse.COI.find(
                                (item) => item.olddocumentName === doc.file_name
                            );
                            if (extractedItem?.data?.liabilities.length) {
                                if (extractedItem && !doc.isVerified) {
                                    const validLiabilities = extractedItem.data.liabilities.filter(liability => new Date(liability.expiry_date) >= new Date());
                                    if (validLiabilities.length === 0) {
                                        const expiredLiability = extractedItem.data.liabilities.reduce((earliest, liability) => {
                                            return new Date(liability.expiry_date) < new Date(earliest.expiry_date) ? liability : earliest;
                                        });
                                        setDisableFileUpload((prev) => ({ ...prev, coi: false }))
                                        setError((prev) => ({
                                            ...prev,
                                            [doc.document_type]: `The document ${doc.file_name} has expired: ${expiredLiability.liability_name}.`
                                        }));
                                        return {
                                            ...doc,
                                            isVerified: false
                                        };
                                    }
                                    const nearestExpiringLiability = validLiabilities.reduce((nearest, liability) => {
                                        return calculateDaysLeft(liability.expiry_date) < calculateDaysLeft(nearest.expiry_date) ? liability : nearest;
                                    });
                                    return {
                                        ...doc,
                                        expire_date: nearestExpiringLiability.expiry_date,
                                        isVerified: true,
                                    };
                                }
                                setError((prev) => ({ ...prev, COI: "Invalid COI Document" }))
                                return { ...doc, isVerified: false };
                            } else {
                                setError((prev) => ({ ...prev, COI: "Invalid COI Document" }))
                                setDisableFileUpload((prev) => ({ ...prev, coi: false }))
                                return { ...doc, isVerified: false };
                            }
                        }
                    }
                    return doc;
                })))

                // setDocumentsDetails(updatedDocuments);
                setShowToastProp({
                    type: "Success",
                    title: "Documents Verified Successfully",
                    message: "Documents have been successfully verified"
                });
                setShowToast(true);
                return true;
            } else {
                setError((prev) => ({ ...prev, general: "Document verification failed" }));
                setShowToastProp({
                    type: "Error",
                    title: "Verification Failed",
                    message: "Document verification failed"
                });
                setShowToast(true);
                setShowLoader(false)
                return false;
            }

        } catch (error) {
            setError((prev) => ({ ...prev, general: "Document verification failed" }));
            setShowToastProp({
                type: "Error",
                title: "Verification Failed",
                message: "Document verification failed"
            });
            setShowToast(true);
            setShowLoader(false)
            return false;
        }
    };


    // PS_CSU__26
    const VerfiyDoc = async () => {
        debugger
        setShowLoader(true);
        const documentsToVerify = documentsDetails.filter((doc) => !doc.isVerified);
        const requests = [];

        for (const doc of documentsToVerify) {
            try {
                let response = ""
                if (doc?.file_url === "") {

                    response = await uploadToS3(`Contractor-Documents/${formPayload.email_id}/${doc.document_type}/${doc.file_name}`, doc.file!);
                }
                const responseUrl = await DownloadS3File(response !== "" ? response : doc.file_url);

                if (responseUrl?.status === 200) {
                    setDocumentsDetails((prevDoc) =>
                        prevDoc.map((prevs) => {
                            if (prevs.file_name === doc.file_name) {
                                if (prevs.document_type === "W9") {
                                    return { ...prevs, file_url: response, isVerified: true };
                                } else {
                                    return { ...prevs, file_url: response };
                                }
                            } else {
                                return prevs;
                            }
                        })
                    );

                    if (doc.document_type !== "W9") {
                        const requestObj = {
                            documentName: doc.file_name,
                            documentLink: responseUrl.url,
                            documentType: doc.document_type === "State licenses" ? "License" : doc.document_type,
                        };
                        requests.push(requestObj);
                    }
                } else {
                    setShowLoader(false);
                    setShowToastProp({
                        type: "Error",
                        title: "Verification Failed",
                        message: "Document verification failed"
                    });
                    setShowToast(true);
                    return;
                }
            } catch (error) {
                setShowLoader(false);
                setShowToastProp({
                    type: "Error",
                    title: "Verification Failed",
                    message: "Document verification failed"
                });
                setShowToast(true);
                return;
            }

        }

        const verificationResult = await verifyAndProcessDocuments(requests);

        if (verificationResult) {

            const allVerified = documentsDetails.every((doc) => doc.isVerified);
            setButtonSubmit(allVerified);
            setButtonVerify(!allVerified);
            setShowLoader(false)
        }

        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    };

    const renderDocument = (doc: DocumentDetailed, index: number) => (
        <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-3 my-2 mx-2" key={index}>
            <a href={doc?.file_url || ''} className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14">
                <img src="../images/pdf-icon.svg" alt="pdf-icon" />{doc?.file_name}
            </a>
            {doc?.expire_date && (
                <div className="d-flex">
                    <div className="d-block">
                        <p className="mb-0 color-black font-10 font-regular">Expires In</p>
                        <span className="color-black font-12 font-semibold">
                            {calculateDaysLeft(doc?.expire_date) < 0 ? 'Expired' : `${calculateDaysLeft(doc?.expire_date)} Days`}
                        </span>
                    </div>
                </div>
            )}
            <button className="icon-btn ms-4" onClick={() => removeFile(index, doc?.document_type, doc?.file_name)}>
                <img src="../images/file-close.svg" alt="close" />
            </button>
        </div>
    );

    const calculateDaysLeft = (expiryDate: string): number => {
        const currentDate = new Date();
        const expiry = new Date(expiryDate);
        const timeDiff = expiry.getTime() - currentDate.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return daysDiff;
    };

    return (
        <>

            {showToast && <CustomToast
                props={showToastProp}
                setShowToast={setShowToast} />}
            {showLoader ? <Loader /> :
                <div className="container-fluid">
                    <div className="row service-form-padding">
                        {!isEdit &&
                            <>
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <h4 className="font-16 color-black font-semibold mb-0">Hi, Welcome to</h4>
                                    <img src="../images/connect-purple.svg" alt="connect" className="ms-2" />
                                </div>
                                <p className="font-15 font-medium color-grey-v6 text-center mb-0">
                                    Provide your detail to onboard you as a contractor
                                </p></>
                        }
                        {/* stepper starts here */}
                        <div className="stepper-container">

                            <div className="row justify-content-center d-none d-md-block">
                                {isEdit &&
                                    <div className="d-flex align-items-center gap-sm-4 gap-3">
                                        <a className="icon-round-btn" onClick={() => { navigate(-1) }}>
                                            <img className="icon-sty" src="../images/back-arrow.svg" alt="back-arrow" />
                                        </a>
                                        <h2 className="color-black font-22 font-semibold mb-0">Edit Your Profile</h2>
                                    </div>
                                }
                                <div className="col-md-12 col-lg-12 my-5">
                                    <div className="stepper-wrapper">
                                        {steps.map((step, index) => (
                                            <div
                                                key={index}
                                                className={`stepper-item ${index < currentStep
                                                    ? 'completed'
                                                    : index === currentStep
                                                        ? 'active'
                                                        : ''
                                                    }`}
                                            >
                                                <div className="step-counter">
                                                    {index < currentStep ? (
                                                        <img src="../images/tick-icon.svg" alt="tick" className="" />
                                                    ) : (
                                                        index + 1
                                                    )}
                                                </div>
                                                <span className="font-14 font-medium step-name text-wrap text-center">
                                                    {step}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* stepper ends here */}
                        {/* mobile & tab stepper */}
                        <div className="d-block d-md-none shadow-sm border-radius-10 p-4 mb-4 mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="mb-0 font-18 font-semibold color-black">{presentIn}</p>
                                <div className={`step-num tab-step-num d-flex justify-content-center align-items-center position-relative ${currentStep < steps.length - 1 ? 'active' : 'step-completed'}`}>
                                    {currentStep < steps.length - 1 ? (
                                        <p className="font-medium font-14 m-0">{currentStep + 1}</p>
                                    ) : (
                                        <img src="../images/tick-icon.svg" alt="tick" className="" />
                                    )}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <p className="mb-0 font-14 font-semibold color-black">
                                    Next : <span className="font-14 font-bold">{nextStep}</span>
                                </p>
                                <span className="font-medium font-14 color-black">
                                    Step <span className="font-semibold">{currentStep + 1}</span> of{" "}
                                    <span className="font-semibold">{steps.length}</span>
                                </span>
                            </div>
                        </div>
                        {/* {presentIn === "Onboarding Contact" ? */}
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-11">
                                <div className="row">
                                    {presentIn === "Onboarding Contact" ?
                                        <OnBoardingForm
                                            formPayload={formPayload}
                                            handleInputChange={handleInputChange}
                                            OnBoardError={OnBoardError}
                                            bindJobtitleDropdown={bindJobtitleDropdown}
                                            bindStateDropdown={bindStateDropdown}
                                            bindCityDropdown={bindCityDropdown}
                                            DisableCity={citiesList.length === 0 ? true : false}
                                            bindRegionDropdown={bindRegionDropdown}
                                            bindHeardAboutusDropdown={bindHeardAboutusDropdown}
                                            termsCondition={termsCondition}
                                            showOthers={showOthers}
                                            handleNext={handleNext}
                                            nextButtonEnabled={nextButtonEnabled}
                                            setTermsCondition={setTermsCondition}
                                            handleCancel={handleCancel}
                                            DropdownData={DropdownData}
                                            isEdit={isEdit}
                                            SaveFromDetails={SaveFromDetails}
                                        ></OnBoardingForm>
                                        : presentIn === "Service Profile" ?
                                            <ServiceProfileForm
                                                formPayload={formPayload}
                                                setFormPayload={setFormPayload}
                                                handleInputChange={handleInputChange}
                                                bankDetails={bankDetails}
                                                setBankDetails={setBankDetails}
                                                OnBoardError={OnBoardError}
                                                bindInterestedAssignmentsDropdown={bindInterestedAssignmentsDropdown}
                                                bindReasonForJoiningDropdown={bindReasonForJoiningDropdown}
                                                bindExpriencedIndustryDropdown={bindExpriencedIndustryDropdown}
                                                bindPastServicesDropdown={bindPastServicesDropdown}
                                                bindHeardAboutusDropdown={bindHeardAboutusDropdown}
                                                others={others}
                                                showOthers={showOthers}
                                                handlePrevious={handlePrevious}
                                                handleNext={handleNext}
                                                currentStep={currentStep}
                                                serviceProfileErrors={serviceProfileErrors}
                                                handleCancel={handleCancel}
                                                PastServiceData={PastServiceData}
                                                IndustriesData={IndustriesData}
                                                ReasonForJoining={ReasonForJoining}
                                                Assignments={Assignments}
                                                isEdit={isEdit}
                                                SaveFromDetails={SaveFromDetails}
                                            ></ServiceProfileForm>
                                            : presentIn === "Personnel" ?
                                                <PersonnalForm
                                                    ServciesData={ServciesData}
                                                    setSelectedServices={setSelectedServices}
                                                    selectedServices={selectedServices}
                                                    setShowOthers={setShowOthers}
                                                    showOthers={showOthers}
                                                    PaymentDiscountsData={PaymentDiscountsData}
                                                    setFormPayload={setFormPayload}
                                                    formPayload={formPayload}
                                                    handlePrevious={handlePrevious}
                                                    handleNext={handleNext}
                                                    currentStep={currentStep}
                                                    Acknowledgements={Acknowledgements}
                                                    setAcknowledgements={setAcknowledgements}
                                                    isEdit={isEdit}
                                                    SaveFromDetails={SaveFromDetails}
                                                    bindSecurityScopeDropdown={bindSecurityScopeDropdown}
                                                    bindCompanySizeDropdown={bindCompanySizeDropdown}
                                                    serviceProfileErrors={serviceProfileErrors}
                                                    handleInputChange={handleInputChange}
                                                    DropdownData={DropdownData}
                                                    dropdownSelected={dropdownSelected}
                                                ></PersonnalForm>
                                                : presentIn === "Contacts" ?
                                                    <ContactForm
                                                        formPayload={formPayload}
                                                        setFormPayload={setFormPayload}
                                                        contacts={contacts}
                                                        setContacts={setContacts}
                                                        handlePrevious={handlePrevious}
                                                        handleNext={handleNext}
                                                        currentStep={currentStep}
                                                        isEdit={isEdit}
                                                        SaveFromDetails={SaveFromDetails}
                                                    ></ContactForm>
                                                    : presentIn === "Branches" ?
                                                        <BranchForm
                                                            formPayload={formPayload}
                                                            setFormPayload={setFormPayload}
                                                            bindStateDropdown={bindStateDropdown}
                                                            bindCityDropdown={bindCityDropdown}
                                                            DisableCity={citiesList.length === 0 ? true : false}
                                                            handleInputChange={handleInputChange}
                                                            handlePrevious={handlePrevious}
                                                            handleNext={handleNext}
                                                            nextButtonEnabled={nextButtonEnabled}
                                                            currentStep={currentStep}
                                                            setAddresses={setAddresses}
                                                            addresses={addresses}
                                                            isEdit={isEdit}
                                                            SaveFromDetails={SaveFromDetails}
                                                        ></BranchForm>
                                                        : presentIn === "Documents" ?
                                                            <DocumentsForm
                                                                error={error}
                                                                handleFileUpload={handleFileUpload}
                                                                renderDocument={renderDocument}
                                                                documentsDetails={documentsDetails}
                                                                disablefileUpload={disablefileUpload}
                                                            ></DocumentsForm>
                                                            :
                                                            null}
                                </div>
                                {presentIn === "Documents" &&
                                    <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                                        <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                                            onClick={() => {
                                                navigate(-1)
                                                setDocumentsDetails([])
                                                setDisableFileUpload({ coi: false, w9: false })
                                                setButtonVerify(false)
                                                setError({ licenses: "", COI: "", W9: "" })
                                            }}>
                                            Cancel
                                        </button>
                                        <div className="d-flex gap-3">
                                            <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                                                onClick={handlePrevious}
                                                disabled={currentStep === 0}>
                                                Previous
                                            </button>

                                            {!buttonSubmit &&
                                                <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"

                                                    disabled={!buttonVerify}
                                                    onClick={() => { VerfiyDoc() }}
                                                >
                                                    Verify
                                                </button>
                                            }

                                            {buttonSubmit &&
                                                <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                                    // disabled={!buttonSubmit}
                                                    onClick={() => { SaveFromDetails() }}
                                                >
                                                    {isEdit ? "Update" : "Sumbit"}
                                                </button>
                                            }

                                            {/* {isEdit &&
                                            <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                                // disabled={!buttonSubmit}
                                                onClick={() => { SaveFromDetails() }}
                                            >
                                                Update
                                            </button>
                                        } */}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
