
import React, { useState } from 'react';

interface TooltipTextProps {
  text: any;
  maxLength: number;
}

const TooltipText: React.FC<TooltipTextProps> = ({ text, maxLength }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const shouldTruncate = text?.length > maxLength;
  const displayedText = shouldTruncate ? text.substring(0, maxLength) + '...' : text;

  return (
    <div 
      className="tooltip-text" 
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {displayedText}
      {isHovered && shouldTruncate && (
        <div
          className="tooltip"
          style={{
            position: 'absolute',
            top: '-5%',  // Slightly above the text
            left: '105%',  // To the right side
            transform: 'translateY(-100%)', // Slightly move upwards
            backgroundColor: '#808080', // Grey background color
            color: '#fff', // White text color
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            whiteSpace: 'nowrap',
            opacity: 0.9, // Slight transparency for the tooltip
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default TooltipText;