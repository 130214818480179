/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getServiceRequest, offerBidPrice } from "../../Service/ServiceRequestView/ServiceRequestView";
import Loader from "../Loader/LoaderComponent";
import { BidApprovalRequest, BidInfoRequest } from "../../Interface/BidApproval";
import { approveBidApi, getBidInfoApi, reinitiateApi } from "../../Service/BidApprovalServices/BidInfoService";
import { contextValueInterface } from "../../Interface/HeaderDetails";
import { ppsContext } from "../../Configs/Constant";


/* eslint-disable jsx-a11y/anchor-is-valid */
function AdminReinitiate() {

    const navigate = useNavigate();
    const { state } = useLocation();
    let { id, status, bidOwner, tab } = state

    useEffect(() => {
        pageload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [serviceRequestDetails, setServiceRequestDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const contextValue: contextValueInterface = useContext(ppsContext);
    const userRole = contextValue.userDetails.role;

    const pageload = async () => {
        try {
            await fetchServiceRequest();
        } catch (error) {
            console.log(error, "pageload")
        }
    }

    const fetchServiceRequest = async () => {
        try {
            // id = "4b92de55-7b0d-43e5-9f1e-706344362e7f"
            setIsLoading(true);
            let response = await getServiceRequest(id)
            if (response.code === 200) {
                setServiceRequestDetails(response.data)
                console.log(response.data.service_details.bid_amount, "Bid ammmm")
                const fetchedBidPrice = response.data.service_details.bid_amount;
                if (fetchedBidPrice !== undefined) {
                    setBidPrice(fetchedBidPrice.toString());
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error, "fetchServiceRequest")
        }
    }

    const [bids, setBids] = useState<any>([]);
    const [bidRequest, setBidRequest] = useState<BidInfoRequest>({
        type: "bidInfo",
        service_request_id: id,
        user_id: "SampleId",
        sort_by: '',
        sort_order: '',
        limit: 10,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState(true);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showToast, setShowToast] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [toastMessage, setToastMessage] = useState<string>('');


    const [bidPrice, setBidPrice] = useState<string>(''); // State for bid price


    const fetchBids = useCallback(async () => {
        try {
            const response = await getBidInfoApi(bidRequest);
            if (response.code === 200) {
                if (bidRequest.limit > response.data.bids.length) {
                    setHasMore(false);
                }
                setBids(response.data.bids);
            } else {
                console.error('Error fetching bids:', response.message);
            }
        } catch (error) {
            console.error('Error fetching bids:', error);
        }
    }, [bidRequest]);

    useEffect(() => {
        fetchBids();
    }, [fetchBids]);



    useEffect(() => {
        adjustInputWidth();
    }, []);

    const hiddenSpanRef: any = useRef(null);

    // Function to adjust input width
    const adjustInputWidth = () => {
        if (hiddenSpanRef.current) {
            return hiddenSpanRef.current.offsetWidth;
        }
        return 0;
    };

    useEffect(() => {
        if (serviceRequestDetails?.service_details?.bid_price !== undefined) {
            setBidPrice(serviceRequestDetails?.service_details?.bid_amount.toString());
        }
    }, [serviceRequestDetails]);



    // Function to calculate total amount with margin and handle default values





    // const handleBidPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     // This regex will allow numbers including decimal point, but no other characters
    //     const regex = /^[0-9]*[.]?[0-9]*$/;

    //     if (regex.test(value) || value === '') {
    //         setBidPrice(value);
    //     }
    // };
    


    const handleBidPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // This regex will allow numbers including decimal point, but no other characters
        const regex = /^[0-9]*[.]?[0-9]*$/;

        if (regex.test(value) || value === '') {
            setBidPrice(value);
        }
    };


    const bidSubmission = async () => {
        const bidPriceValue = parseFloat(bidPrice.replace(/[^0-9.]/g, '')); // Ensure the bidPrice is a valid float
        let marginPercentageValue = 0;
        let marginAmountValue = 0;

        // Calculate margin values but use bidPriceValue as new_offer_price
        let marginFinalPrice = bidPriceValue;


        const approvalRequestData = {
            type: 'rejected',
            bid_person: 'rm',
            service_request_id: id,
            new_offer_price: bidPriceValue,
            margin_percentage: marginPercentageValue,
            margin_amount: marginAmountValue,
            margin_final_value: marginFinalPrice
        };

        setIsLoading(true);
        try {
            const response = await reinitiateApi(approvalRequestData);

            if (response.code === 200) {
                setToastMessage('Bid reinitiated successfully');
                setShowToast(true);
                await fetchBids(); // Refresh the bids if needed
                await fetchServiceRequest();
                setTimeout(() => setShowToast(false), 3000);
            } else {
                console.error('Error reinitiating bid:', response.message);
                setToastMessage('Error reinitiating bid');
                setShowToast(true);
                setTimeout(() => setShowToast(false), 3000);
            }
        } catch (error) {
            console.error('Error reinitiating bid:', error);
            setToastMessage('Error reinitiating bid');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? <Loader /> :
                <div className="container-fluid p-sm-4 p-2 pt-md-5 pt-4 mt-md-4">
                    <div className="d-flex justify-content-between align-items-start w-100 gap-3 px-4 ">
                        <div className="d-flex align-items-start flex-wrap gap-3 justify-content-between w-100">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-3 flex-wrap">
                                    <div className="d-flex align-items-center gap-lg-4 gap-2">
                                        <button className="icon-round-btn cust-round-width me-1"
                                            onClick={() => { navigate("/jobs-and-bids", { state: { tab: tab } }) }} >
                                            <img
                                                className="icon-sty"
                                                src="../images/back-arrow.svg"
                                                alt="back-arrow"
                                            />
                                        </button>
                                        <h2 className="color-black font-22 font-semibold mb-0 lh-inherit">
                                            {serviceRequestDetails?.service_details?.service_request}
                                        </h2>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <span className="custom-status await-status-legend">
                                            <span className="await-status-sub-legend" />
                                        </span>
                                        <span className="font-15 font-semibold delay-color">
                                            {userRole === "Regional Manager" ?
                                                serviceRequestDetails?.service_details?.rm_status === "Awaiting Approval" ? "Waiting for Your Response" :
                                                    serviceRequestDetails?.service_details?.rm_status === "Open" ? "Open" :
                                                        serviceRequestDetails?.service_details?.rm_status === "In Progress" ? "In Progress" :
                                                            serviceRequestDetails?.service_details?.admin_status === "Rejected" && serviceRequestDetails?.service_details?.service_start_date > new Date().toISOString().split('T')[0] ? "Re-initiate" :
                                                                serviceRequestDetails?.service_details?.admin_status === "Rejected" ? "Rejected" :
                                                                    serviceRequestDetails?.service_details?.rm_status === "Closed" ? "Closed" : "" :
                                                userRole === "Admin" ?
                                                    serviceRequestDetails?.service_details?.admin_status === "Open" && status !== 'closed' ? "Open" :
                                                        serviceRequestDetails?.service_details?.admin_status === "Awaiting Approval" ? "Awaiting Approval" :
                                                            serviceRequestDetails?.service_details?.admin_status === "Rejected" && serviceRequestDetails?.service_details?.service_start_date > new Date().toISOString().split('T')[0] ? "Re-initiate" :
                                                                serviceRequestDetails?.service_details?.admin_status === "Rejected" ? "Rejected" :
                                                                    serviceRequestDetails?.service_details?.admin_status === "In Progress" ? "In Progress" :
                                                                        status === 'closed' ? "Closed" :
                                                                            status === 'closed' ? "Closed" : "" : ""
                                            }
                                        </span>
                                    </div>
                                </div>
                                <span className="font-14 font-medium color-grey-v4 d-flex align-items-center flex-wrap mt-2 cust-ms">
                                    {moment(serviceRequestDetails?.service_details?.service_start_date)?.format('ddd, DD MMM YYYY')} - {moment(serviceRequestDetails?.service_details?.service_end_date)?.format('ddd, DD MMM YYYY')}  {moment(serviceRequestDetails?.service_details?.service_start_time, 'HH:mm')?.format('h:mm A')} - {moment(serviceRequestDetails?.service_details?.service_end_time, 'HH:mm')?.format('h:mm A')}
                                    <span className="cust-dot" />
                                    {serviceRequestDetails?.service_details?.service_state}
                                    <span className="cust-dot" />
                                    {serviceRequestDetails?.service_details?.incident_name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="px-md-5 px-sm-3 px-0 mx-3 mx-sm-4">
                        {/* tabs */}
                        <div className="tab-scroll my-4 px-3">
                            {userRole === 'Admin' && serviceRequestDetails?.service_details?.admin_status !== "Open" ? (
                                <ul
                                    className="nav nav-pills cust-nav-detail-pills flex-nowrap w-100"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link cust-nav-link active text-nowrap"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            Job Info
                                        </button>
                                    </li>
                                    {serviceRequestDetails?.service_details?.service_start_date > new Date().toISOString().split('T')[0] &&
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link cust-nav-link text-nowrap"
                                                id="pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-profile"
                                                aria-selected="false"
                                            >
                                                Bid Info
                                            </button>
                                        </li>}

                                </ul>
                            ) : null}
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            {/* 1st tab */}
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                {/* {status === "in-review" ?
                                    getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time) < 180 ?
                                        <div className="d-flex align-items-center cust-padding gap-2">
                                            <img src="../images/await-time.svg" alt="await-time" />
                                            <span className="font-medium font-14 sucess-color">
                                                {`We're doing our best to provide you with a best Amount. Please wait `}
                                                <Countdown startValue={180 - getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time)} /> {`minutes.`}
                                            </span>
                                        </div> :
                                        <div className="d-flex align-items-start cust-padding gap-2 delay-msg">
                                            <img className="mt-1" src="../images/delay-time.svg" alt="delay-time" />
                                            <span className="font-medium font-14 ">It’s taking longer than expected.</span>
                                        </div> :
                                    <></>
                                } */}
                                <div className="row mt-2">
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Client DBA Name
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.customer_dba}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Job Raised Date
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {moment(serviceRequestDetails?.service_details?.created_on)?.format('MMM DD, YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Location Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.location_type}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Officer Request Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.request_types?.map((value: any) => (
                                                    value?.request_type_name + '(' + value?.security_people_count + ') '
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Officer Duties Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.requested_duties?.map((value: any) => (
                                                    value?.duty_name
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Service Location
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.address_line_one + ', ' + serviceRequestDetails?.service_details?.address_line_two
                                                    + ', ' + serviceRequestDetails?.service_details?.service_city + ', ' + serviceRequestDetails?.service_details?.service_state
                                                    + ', ' + serviceRequestDetails?.service_details?.service_zip_code}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-3 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                About Service Request
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.about_service_request}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-3 mb-4">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Availed Discounts
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.discount_percentage !== "No" ?
                                                    `Complete payment in ${serviceRequestDetails?.service_details?.discount_days} Days and get the ${serviceRequestDetails?.service_details?.discount_percentage}% Discount offer` :
                                                    "No offer applied"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd tab */}
                            {/* common tab */}

                            <div
                                className="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >

                                {/* Admin bids */}

                                <div className="d-block mt-3">
                                    <h3 className="font-22 color-black font-semibold mb-4">
                                        Make an Offer
                                    </h3>
                                    {/* input field */}
                                    <div className="enter-price-field p-3 px-5 d-flex align-items-center flex-column pt-4 justify-content-center">
                                        <span className="font-14 font-semibold color-grey-purple text-center">
                                            Enter Per Hour Quote Price
                                        </span>
                                        <div className="d-flex position-relative align-items-center justify-content-center custom-enter-price my-3">
                                            <span className="font-32 color-black-v2 secondary-font-medium">
                                                $
                                            </span>
                                            <input
                                                type="text"
                                                id="service"
                                                name="bid_price"
                                                className="border-0 d-block text-start bg-transparent font-32 color-black-v2 secondary-font-medium enter-price-input w-100"
                                                placeholder="0"
                                                value={bidPrice}
                                                onChange={handleBidPriceChange}
                                                style={{ width: 'auto', minWidth: '100px' }}  // Set minWidth to ensure it's not too small
                                            />
                                            {/* Hidden span to measure input width */}
                                            <span ref={hiddenSpanRef} className="d-none position-absolute">
                                                {bidPrice}
                                            </span>
                                        </div>
                                    </div>

                                    <button
                                        className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4 mt-4"
                                        onClick={bidSubmission}
                                    >
                                        Confirm Bid
                                    </button>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default AdminReinitiate;