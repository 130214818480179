export interface ProfileServicesInterface {
    retrieveProfileData: string;
    changeForgotPassword: string;
  }
  
  // retrieve-profile-details : request payload
  
  export interface RetrieveProfileDataPayloadInterface {
    type: "customer" | "contractor"; // "required,alpha"
    profile_uuid: string; // "required"
  }
  
  // API Response
  export interface APIResponseInterface {
    status: "Success" | "Error";
    code: number;
    message: string;
    data?: any;
    errors?: ErrorInterface[];
    meta?: MetaInterface;
    request_id?: string;
    timestamp?: string;
  }
  
  export interface ErrorInterface {
    field?: string; // Optional because of 'omitempty'
    message: string; // Descriptive error message
    code: string; // Custom error code
  }
  
  export interface MetaInterface {
    total_count: number; // Total number of items
  }
  
  // Profile
  // profile info section enum
  
  export enum ProfileInfoSection {
    OnboardingContact = "OnboardingContact",
    ServiceProfile = "ServiceProfile",
    Personel = "Personel",
    Contacts = "Contacts",
    Branches = "Branches",
    Documents = "Documents",
  }
  
  export interface ProfileDataInterface {
    customer_profile_data?: CustomerProfileInterface; // "omitempty"
    contractor_profile_data?: ContractorProfileInterface; // "omitempty"
  }
  
  export interface CustomerProfileInterface {
    customer_uuid: string;
    customer_name: string;
    customer_dba: string;
    industry_uuid: string;
    industry_name: string;
    business_email: string;
    phone_number: string;
    address_line_one: string;
    address_line_two: string;
    zip_code: string;
    city: string;
    state: string;
    region: string;
    is_mark_active: boolean;
    is_account_activated: boolean;
    onboarding_status?: boolean;
    is_admint_invited: boolean;
    is_account_requested: boolean;
  }
  
  export interface ContractorProfileInterface {
    contractor_details: ContractorDetailsInterface;
    industry_data: IndustryDataInterface[];
    documents: ContractorDocumentsInterface[];
    service_mappings: ContractorServiceMappingInterface[];
    branch_locations: ContractorBranchLocationsInterface[];
    team_details: ContractorTeamDetailsInterface[];
    dropdowns: OnBoardingDropdownInterface[];
    banking_details: bankingDetails[]
  }

  export interface bankingDetails{
    bank_name:string,
    routing_number:string,
    account_holder_name: string
    account_number: string
    is_default: boolean
    
  }
  
  export interface ContractorDetailsInterface {
    is_mark_active: boolean;
    contractor_uuid: string;
    contractor_payment: ContractorPaymentInterface;
    first_name: string;
    last_name: string;
    contractor_email: string;
    phone_number: string;
    legal_business_name: string;
    dba_business_name: string;
    business_address_line_one: string;
    business_address_line_two: string;
    business_zip_code: string;
    business_city: string;
    business_state: string;
    region: string;
    region_id: string;
    website_media_link: string;
    year_founded: string;
    affiliated_with?: string;
    tracktik_willingness: boolean;
    orgainzation_highlight?: string;
    extra_guard_trainings?: string;
    is_abled_off_duty_police?: string;
    ack: string;
    is_only_contact?: string;
    has_branches?: string;
    after_hours_contact_name: string;
    after_hours_contact_phone: string;
    after_hours_contact_email: string;
    is_admin_invited: boolean;
    on_boarding_status: boolean;
    is_account_activated: boolean;
    is_flagged: boolean;
    is_account_requested: boolean;
    non_solicitation: string;
    indemnification: boolean;
    heard_about_us: string;
    invited_on: string;
    requested_on:string;
  }
  
  export interface ContractorPaymentInterface {
    contractor_payment_uuid: string;
    fee_percentage: number;
    payment_days: string;
  }
  
  export interface IndustryDataInterface {
    industry_uuid: string;
    industry_name: string;
    others_name: string;
  }
  
  export interface ContractorDocumentsInterface {
    contractor_documents_uuid: string;
    document_type: string;
    document_state_name?: string;
    document_url: string;
    license_number?: number;
    expiry_date?: string;
    file_name: string;
  }
  
  export interface ContractorServiceMappingInterface {
    guard_count: string;
    request_type_name?: string;
    request_type_uuid: string;
    is_past_service: boolean;
    others_name: string
  }
  
  export interface ContractorBranchLocationsInterface {
    contractor_branch_location_uuid: string;
    branch_name: string;
    address_line_one: string;
    address_line_two: string;
    zip_code: string;
    city: string;
    state: string;
  }
  
  export interface ContractorTeamDetailsInterface {
    contractor_team_detail_uuid: string;
    team_name: string;
    incharge_name: string;
    incharge_phone: string;
    incharge_email: string;
  }
  
  export interface OnBoardingDropdownInterface {
    on_boarding_dropdown_uuid: string;
    dropdown_name: string;
    dropdown_value: string;
    dropdown_type: string;
    others_name: string;
  }
  
  
  
  export interface ProfileAcknowledgmentsInterface {
    subcontracting: boolean;
    drugScreen: boolean;
    randomDrugScreening: boolean;
    backgroundCheck: boolean;
  }
  
  export interface PasswordValidationErrorInterface {
    currentPassword: string;
    newPassword: string;
  }
  
  export interface PasswordChangeInterface {
    current_password: string;
    new_password: string;
  }
  
  export interface ChangePasswordPayloadInterface {
    mode: string;
    user_id: string;
    user_uuid: string;
    email_id : string;
    current_password: string;
    new_password:  string;
  }
  
  export interface ToastInterface {
    isToast: boolean;
    toastMessage: string;
    toastDescription: string;
    toastType: string;
  }
  
  export interface SecurityRolesInterface {
    [key: string]: string;
  }
  
  export interface TeamNameHeaderInterface{
    [key: string]: string;
  }